import { FC, ReactNode } from "react";
import { DatePicker, Form, Input, Select, Space } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";

import { FormValues, SelectOption } from "@ni/common/types";

import styles from "./styles.module.scss";

interface Option extends Pick<SelectOption, "label"> {
  value: string | null;
}

export interface FilterInput {
  type: "select" | "search" | "date" | "dateRange";
  name: string;
  options?: Option[];
  placeholder?: string;
}

export interface FilterProps {
  filterInputs: FilterInput[];
  wrap?: boolean;
  compact?: boolean;
  size?: SizeType;
  onValuesChange?: ((changedValues: unknown, values: FormValues) => void) | undefined;
}

interface FiltersWrapperProps extends Pick<FilterProps, "compact" | "wrap"> {
  children: ReactNode;
}

const { Option: AntdOption } = Select;
const { RangePicker } = DatePicker;

const FiltersWrapper: FC<FiltersWrapperProps> = ({ compact, wrap, children }) => {
  if (compact) {
    return (
      <Space.Compact className={styles["inputs"]} block={true}>
        {children}
      </Space.Compact>
    );
  }

  return (
    <Space size={24} className={styles["inputs"]} wrap={wrap}>
      {children}
    </Space>
  );
};

export const Filter: FC<FilterProps> = ({
  filterInputs,
  wrap = true,
  compact = false,
  size = "large",
  onValuesChange,
}) => {
  const [form] = Form.useForm<FormValues>();

  const renderFilters = () =>
    filterInputs.map(({ type, name, options, placeholder }) => {
      let input = <div />;

      if (type === "search") {
        input = <Input size={size} placeholder={placeholder} />;
      }

      if (type === "select") {
        input = (
          <Select size={size} placeholder={placeholder}>
            {(options ?? []).map(option => (
              <AntdOption key={option.value} value={option.value}>
                {option.label}
              </AntdOption>
            ))}
          </Select>
        );
      }

      if (type === "date") {
        input = <DatePicker size={size} showTime={true} format="YYYY-MM-DD HH:mm:ss" placeholder={placeholder} />;
      }

      if (type === "dateRange") {
        input = <RangePicker size={size} showTime={true} format="YYYY-MM-DD HH:mm:ss" placeholder={["From", "To"]} />;
      }

      return (
        <Form.Item key={name} name={name}>
          {input}
        </Form.Item>
      );
    });

  if (filterInputs.length === 0) {
    return null;
  }

  return (
    <Form form={form} className={styles["filter-bar"]} onValuesChange={onValuesChange}>
      <FiltersWrapper compact={compact} wrap={wrap}>
        {renderFilters()}
      </FiltersWrapper>
    </Form>
  );
};
