import { Button, Tooltip } from "antd";

import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { SetReduxState } from "@ni/common/hooks";
import { CopyIcon } from "@ni/common/icons";
import { ParameterTable } from "@ni/sdk/models";

import styles from "../../styles.module.scss";

export type ModalState = {
  pct: ParameterTable;
  type: "create" | "rename" | "duplicate" | "delete" | null;
};

interface Props {
  pct: ParameterTable;
  pctLength: number;
  setModalState: SetReduxState<ModalState>;
}

const PctActions = ({ pct, pctLength, setModalState }: Props) => {
  const onRename = () => {
    setModalState({ pct, type: "rename" });
  };

  const onDuplicate = () => {
    setModalState({ pct, type: "duplicate" });
  };

  const onDelete = () => {
    setModalState({ pct, type: "delete" });
  };

  const actions = [
    <Tooltip key="pct-rename-tooltip" title="Rename">
      <Button size="small" type="link" onClick={onRename} icon={<EditOutlined />} className={styles["action-button"]} />
    </Tooltip>,
    <Tooltip key="pct-duplicate-tooltip" title="Duplicate">
      <Button size="small" type="link" onClick={onDuplicate} icon={<CopyIcon />} className={styles["action-button"]} />
    </Tooltip>,
  ];

  if (pctLength > 1) {
    actions.push(
      <Tooltip key="pct-delete-tooltip" title="Delete">
        <Button
          size="small"
          type="link"
          onClick={onDelete}
          icon={<DeleteOutlined />}
          className={styles["action-button"]}
        />
      </Tooltip>,
    );
  }

  return actions;
};

export { PctActions };
