import { FC } from "react";

import { PctList, SettingsPageLayout, TooltipInfo } from "@ni/common/ui";

export const PctPage: FC = () => {
  return (
    <SettingsPageLayout
      size="full"
      level="root"
      pageTitle="Pricing Control Tables"
      pageSubtitle={
        <TooltipInfo
          largeLabel="In setting up fees, velocity controls, or other values for each product separate requirements could be
          applied to a specific segment of cardholders."
          tooltipProps={{
            title:
              "While each product has one generalized pricing control table; you can still configure additional pricing tables that deliver different pricing propositions under the same product, for some of your cardholder segments, for example: Staff segment PCT, VIP customers PCT... etc.",
          }}
        />
      }
    >
      <PctList />
    </SettingsPageLayout>
  );
};
