import { Form, FormInstance, Space } from "antd";

import { FormValues } from "@ni/common/types";
import { NetworkForm, PaymentSchemeTable } from "@ni/common/ui";
import { Tenant } from "@ni/sdk/models";

interface Props {
  form: FormInstance<FormValues>;
  tenant?: Tenant;
  gap?: number;
  disabled?: boolean;
}

const TenantSchemeForm = ({ form, tenant, gap = 24, disabled = false }: Props) => {
  const fiType = Form.useWatch("fiType", form) as "AFFILIATED" | "PRINCIPAL";

  return (
    <Space direction="vertical" size={gap}>
      <NetworkForm.Select
        formItemOptions={{
          name: "fiType",
          label: "Financial institution type",
          tooltip: "Select whether the financial institution is a Principal member or Affiliated member of the scheme.",
          // this rule is not required for now - will be added later
          // rules: [
          //   {
          //     required: true,
          //     message: "Financial institution type is required",
          //   },
          // ],
        }}
        placeholder="Select financial institution type"
        optionList={[
          {
            label: "Principal Bank",
            value: "PRINCIPAL",
          },
          {
            label: "Affiliated Bank",
            value: "AFFILIATED",
          },
        ]}
        disabled={disabled}
      />

      {fiType === "AFFILIATED" && (
        <NetworkForm.String
          formItemOptions={{
            name: "principalName",
            label: "Principal financial institution name",
            tooltip:
              "This parameter specifies the Principal Financial Institution Name, it will be used to Generate Quarterly Matrix Report.",
            // this rule is not required for now - will be added later
            // rules: [
            //   {
            //     required: true,
            //     message: "Principal financial institution name is required!",
            //   },
            //   { max: 255, message: "Principal financial institution name must have maximum 255 characters." },
            // ],
          }}
          disabled={disabled}
        />
      )}

      <PaymentSchemeTable form={form} type={fiType} tenant={tenant} disabled={disabled} />
    </Space>
  );
};

export { TenantSchemeForm };
