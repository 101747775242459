import { ParameterTable } from "@ni/sdk/models";

import { FilterInputProps } from "../FilterControls";

export const inputs: FilterInputProps[] = [
  {
    name: "sort",
    type: "select",
    props: {
      placeholder: "Sort",
      options: [
        { value: "asc_ec", label: "Numerical Ascending (1-999)" },
        { value: "desc_ec", label: "Numerical Descending (999-1)" },
        { value: "asc", label: "Ascending (A-Z)" },
        { value: "desc", label: "Descending (Z-A)" },
      ],
    },
  },
];

export const sortFunctions = {
  asc: (a: ParameterTable, b: ParameterTable) => (a.displayName ?? "").localeCompare(b.displayName ?? ""),
  desc: (a: ParameterTable, b: ParameterTable) => (b.displayName ?? "").localeCompare(a.displayName ?? ""),
  asc_ec: (a: ParameterTable, b: ParameterTable) => (a.externalCode ?? "").localeCompare(b.externalCode ?? ""),
  desc_ec: (a: ParameterTable, b: ParameterTable) => (b.externalCode ?? "").localeCompare(a.externalCode ?? ""),
};
