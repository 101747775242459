import { FC } from "react";
import { Alert, Form, FormInstance, Space, Typography } from "antd";

import { IJiraCommitForm } from "@ni/common/types";

import { NetworkForm } from "../FormInput";

export const JiraCommitForm: FC<{ form: FormInstance<IJiraCommitForm>; hideFiInput?: boolean }> = ({
  form,
  hideFiInput,
}: {
  form: FormInstance<IJiraCommitForm>;
  hideFiInput?: boolean;
}) => {
  const handleChange = (value: string[]) => {
    const uniqueTags = Array.from(new Set(value.map(tag => tag.toUpperCase())));
    form.setFieldsValue({ jiraTicketId: uniqueTags });
  };

  return (
    <Form form={form} layout="vertical">
      <NetworkForm.Select
        formItemOptions={{
          name: "jiraTicketId",
          label: "Jira Ticket ID",
          tooltip: "This ensures your changes are aligned to project guidelines",
          rules: [
            { required: true, message: "Jira ticket id is required" },
            { pattern: /^[A-Z]+-\d+(?:,\s?[A-Z]+-\d+)*$/, message: "Invalid jira ticket id" },
          ],
          className: "m-t-16",
        }}
        mode="tags"
        tokenSeparators={[",", " "]}
        notFoundContent={null}
        maxTagCount={3}
        placeholder="Example: XXXX-000"
        onChange={handleChange}
      />

      <NetworkForm.String
        formItemOptions={{
          name: "commitComment",
          label: "Additional Notes",
          tooltip: "This will be added to commit message for reviewer",
          rules: [{ required: true, message: "Additional notes is required" }],
        }}
        placeholder="Enter one line comment"
      />

      <NetworkForm.String
        formItemOptions={{
          name: "originBranch",
          label: "Origin Branch Name",
        }}
        placeholder="Origin Branch"
      />

      <NetworkForm.String
        formItemOptions={{
          name: "sourceBranch",
          label: "Source Branch Name",
          tooltip: "Feature branch name",
        }}
        placeholder="Source Branch"
      />

      <NetworkForm.String
        formItemOptions={{
          name: "destinationBranch",
          label: "Destination Branch Name",
        }}
        placeholder="Destination Branch"
      />

      {!hideFiInput && (
        <Alert
          style={{ padding: 12 }}
          message={
            <Space direction="vertical" size={12}>
              <Typography.Paragraph style={{ marginBottom: 0 }}>
                Please open{" "}
                <Typography.Link href="https://jira.network.ae/confluence/display/NICE/Standard+Product%3A+Customer+List">
                  this page
                </Typography.Link>{" "}
                and update it first. Ensure that FI Code is unique across all the regions. Once the page is updated fill
                in the FI Code below:
              </Typography.Paragraph>

              <NetworkForm.String
                formItemOptions={{
                  name: "fiCode",
                  label: "FI Code",
                  className: "m-b-0",
                }}
                placeholder="Enter one line comment"
              />
            </Space>
          }
        />
      )}
    </Form>
  );
};
