import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Product, ProductState } from "@ni/sdk/models";

import { useReduxState } from "../store";

const useProductStateFormDisabled = () => {
  const [currentProduct] = useReduxState<Product>("currentProduct");
  const { pathname } = useLocation();

  const isDisabled = useMemo(() => {
    const isProductOrPctPage = pathname.includes("/product/") || pathname.includes("/pct/");
    const isProductLocked =
      currentProduct?.productState === ProductState.LIVE ||
      currentProduct?.productState === ProductState.REJECTED ||
      currentProduct?.productState === ProductState.UAT ||
      currentProduct?.productState === ProductState.UAT_PENDING;

    return isProductOrPctPage && isProductLocked;
  }, [currentProduct, pathname]);

  return isDisabled;
};

export { useProductStateFormDisabled };
