import { useCallback, useEffect, useMemo } from "react";
import { Button, Form, Modal, Space, Typography } from "antd";
import { Rule } from "antd/es/form";
import { usePct } from "libs/product-settings/src/lib/hooks";

import { useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { ParameterTable, Product } from "@ni/sdk/models";

import { NetworkForm } from "../../../FormInput";
import { ModalState } from "../actions";

interface Props {
  pct: ParameterTable;
}

const PctModal = ({ pct }: Props) => {
  const [form] = Form.useForm<FormValues>();
  const [modalState, setModalState] = useReduxState<ModalState>("pctModal");
  const [currentProduct] = useReduxState<Product>("currentProduct");

  const { onCreatePct, onRenamePct, onDuplicatePct, onDeletePct } = usePct({
    pctId: pct?.id ?? 0,
  });

  useEffect(() => {
    if (modalState?.type === "duplicate") {
      form.setFieldsValue({ displayName: `Copy of ${pct?.displayName}` });
    } else if (modalState?.type === "rename") {
      form.setFieldValue("displayName", pct?.displayName);
    }
  }, [form, modalState?.type, pct?.displayName]);

  const onCancel = useCallback(() => {
    setModalState({ pct: {}, type: null });
    form.setFieldValue("displayName", "");
  }, [form, setModalState]);

  const onFinish = useCallback(
    (values: FormValues) => {
      if (modalState?.type === "create") {
        void onCreatePct(values);
      }

      if (modalState?.type === "rename") {
        void onRenamePct(values);
      }

      if (modalState?.type === "duplicate") {
        void onDuplicatePct(values as { displayName: string });
      }

      setModalState({ pct: {}, type: null });
      form.setFieldValue("displayName", "");
    },
    [form, modalState?.type, onCreatePct, onDuplicatePct, onRenamePct, setModalState],
  );

  const onDelete = useCallback(() => {
    void onDeletePct().then(() => {
      setModalState({ pct: {}, type: null });
    });
  }, [onDeletePct, setModalState]);

  const modal = useMemo(() => {
    const existingNames = new Set(
      currentProduct?.parameterTables
        ?.filter(x => x.id !== modalState?.pct?.id)
        .map(x => x?.displayName?.toLowerCase().trim() ?? ""),
    );

    const rules: Rule[] = [
      {
        type: "string",
        required: true,
        max: 80,
        message: "The name is required and must not exceed 80 characters.",
      },
      {
        validator: (_, value: string) => {
          const trimmedValue = value.toLowerCase().trim();

          if (existingNames.has(trimmedValue)) {
            return Promise.reject(new Error("A table with this name already exists under this product."));
          }

          return Promise.resolve();
        },
      },
    ];

    switch (modalState?.type) {
      case "create":
        return {
          title: "Create Pricing Control Table",
          content: (
            <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
              <Space direction="vertical" size={8} className="w-p-100">
                <NetworkForm.String
                  ref={e => e && e?.focus()}
                  autoFocus={true}
                  formItemOptions={{
                    name: "displayName",
                    label: "Please provide a name for the new Pricing Control Table",
                    rules,
                  }}
                />

                <Typography.Text type="secondary" className="m-b-0">
                  Creating a new table will inherit Default table values.
                </Typography.Text>
              </Space>
            </Form>
          ),
          footer: [
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            <Button key="create" type="primary" htmlType="submit" onClick={form.submit} danger={true}>
              Create
            </Button>,
          ],
        };
      case "rename":
        return {
          title: "Rename Pricing Control Table",
          content: (
            <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
              <NetworkForm.String
                ref={e => e && e?.focus()}
                formItemOptions={{
                  name: "displayName",
                  label: "Please provide a new name for the Pricing Control Table",
                  rules,
                }}
              />
            </Form>
          ),
          footer: [
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            <Button key="rename" type="primary" htmlType="submit" onClick={form.submit} danger={true}>
              Rename
            </Button>,
          ],
        };
      case "duplicate":
        return {
          title: "Duplicate Pricing Control Table",
          content: (
            <Form form={form} onFinish={onFinish} autoComplete="off" layout="vertical">
              <NetworkForm.String
                ref={e => e && e?.focus()}
                formItemOptions={{
                  name: "displayName",
                  label: "Please provide a name for the new Pricing Control Table",
                  rules,
                }}
              />
            </Form>
          ),
          footer: [
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            <Button key="duplicate" type="primary" htmlType="submit" onClick={form.submit} danger={true}>
              Duplicate
            </Button>,
          ],
        };
      case "delete":
        return {
          title: "Delete Pricing Control Table",
          content: (
            <Typography.Text>
              Are you sure you want to delete{" "}
              <strong>
                &quot;{pct.displayName} ({pct.externalCode})&quot;
              </strong>
              ? This action cannot be undone.
            </Typography.Text>
          ),
          footer: [
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>,
            <Button key="delete" type="primary" onClick={onDelete} danger={true}>
              Delete
            </Button>,
          ],
        };
      default:
        return null;
    }
  }, [
    currentProduct?.parameterTables,
    form,
    modalState?.pct?.id,
    modalState?.type,
    onCancel,
    onDelete,
    onFinish,
    pct?.displayName,
    pct?.externalCode,
  ]);

  return (
    <Modal
      open={!!modalState?.type}
      title={modal?.title}
      footer={modal?.footer}
      onCancel={onCancel}
      destroyOnClose={true}
      getContainer={false}
    >
      {modal?.content}
    </Modal>
  );
};

export { PctModal };
