import { FC, useMemo } from "react";
import { Form, Space } from "antd";
import cn from "classnames";
import { parseInt } from "lodash";
import { StoreValue } from "rc-field-form/lib/interface";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { TENANT_COUNTRY } from "@ni/common/constants";
import { useHydrateForm, useReduxState } from "@ni/common/hooks";
import { nicDeclTransFeeValPredefRcOptions, nicQuasiCashOptions } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import {
  CustomFormWrapper,
  NetworkForm,
  PageItemLayoutElements,
  PageItemLayoutGeneral,
  PageItemLayoutGroup,
  TooltipInfo,
} from "@ni/common/ui";
import { filterByDisplayValue } from "@ni/common/utils";
import { TenantApi } from "@ni/sdk/apis";
import { ChangeTenantRequest, Tenant } from "@ni/sdk/models";

import styles from "./styles.module.scss";

const tenantsServiceApi = new TenantApi();

const stringsKeys = [
  "nic-domestic-country",
  "vat-fees-apply",
  "vat-rate",
  "vat-membership-apply",
  "vat-cardprod-fees-apply",
  "vat-trans-fees-apply",
  "vat-markup-fee-apply",
  "vat-other-fees-apply",
  "nic-decl-trans-fee-val-pref-ex_bal_inq",
  "vat-late-fee-apply",
  "vat-ovl-fee-apply",
  "vat-nsf-fee-apply",
  "intareg-enabled",
];

// TEMPORARY FIX - Explanation:
// flag was added to differentiate whether the user cleared values or not, in order to correctly hydrate list.
const quasiClearCode = "quasi-cash-cleared-by-user";
const declineFeeClearCode = "decl-trans-fee-val-pref-rc-cleared-by-user";

export const FeesTenantConfiguration: FC = () => {
  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();
  const [tenant, setTenant] = useReduxState<Tenant>("tenant", {});
  const [, setIsLoading] = useReduxState<boolean>("isLoading");
  const isIntraregional = Form.useWatch<boolean>("intareg-enabled", form);

  useHydrateForm({
    form,
    entityFields: tenant.tenantValues ?? [],
    keys: {
      strings: stringsKeys,
      lists: [
        "nic-quasi-cash",
        "nic-decl-trans-fee-val-pref-rc",
        "nic-bal-inq-fee-intrareg-pref-countries",
        "nic-ips_trans_intrareg-countries",
      ],
    },
    onAfterHydrate: (values: FormValues) => {
      form.setFieldValue("nic-domestic-country", values["nic-domestic-country"] ?? tenant?.countryCode);
    },
  });

  const { quasiCleared, declineFeeCleared, relatedLinks, hasCreditProduct } = useMemo(() => {
    const quasiCleared = tenant.tenantValues?.find(x => x.fieldCode === quasiClearCode)?.value === "true";
    const declineFeeCleared = tenant.tenantValues?.find(x => x.fieldCode === declineFeeClearCode)?.value === "true";

    const relatedLinks =
      tenant?.products?.map(product => ({
        href: `/tenant/${tenant?.id}/product/${product?.id}/fees-settings?tab=annual`,
        label: `${product?.displayName} - Fee Settings`,
      })) ?? [];

    const hasCreditProduct = tenant.products?.some(
      product =>
        product.productValues?.find(x => x.fieldCode === "product-type")?.value === "Credit" &&
        product.productValues?.find(x => x.fieldCode === "balance-owner")?.value === "CMS",
    );

    return { quasiCleared, declineFeeCleared, relatedLinks, hasCreditProduct };
  }, [tenant.tenantValues, tenant?.id, tenant?.products]);

  const onFinish = (values: FormValues) => {
    setIsLoading(true);

    const tenantId = parseInt(id ?? "0", 10);
    const requestBody: ChangeTenantRequest = {
      tenantValues: Object.keys({
        "nic-ips_trans_intrareg-countries": [],
        "nic-bal-inq-fee-intrareg-pref-countries": [],
        ...values,
      }).map((val: string) => {
        if (val === "nic-domestic-country" || val === "vat-rate") {
          return {
            fieldCode: val,
            value: (values[val] as string) || "",
          };
        }
        if (val === "nic-quasi-cash" || val === "nic-decl-trans-fee-val-pref-rc") {
          return {
            fieldCode: val,
            value: (values[val] as unknown as string[])?.join(",") || "",
          };
        }
        if (val === "nic-ips_trans_intrareg-countries" || val === "nic-bal-inq-fee-intrareg-pref-countries") {
          if (values["intareg-enabled"]?.toString() === "true") {
            return {
              fieldCode: val,
              value: (values[val] as unknown as string[])?.join(",") || "",
            };
          }
          return { fieldCode: val, value: "" };
        }
        return {
          fieldCode: val,
          value: values[val] ? "true" : "false",
        };
      }),
    };

    requestBody.tenantValues?.push({
      fieldCode: quasiClearCode,
      value: (values["nic-quasi-cash"] as string[])?.join(",") === "" ? "true" : "false",
    });

    requestBody.tenantValues?.push({
      fieldCode: declineFeeClearCode,
      value: (values["nic-decl-trans-fee-val-pref-rc"] as string[])?.join(",") === "" ? "true" : "false",
    });

    tenantsServiceApi
      .editTenant(requestBody, tenantId)
      .then(res => {
        setIsLoading(false);
        setTenant(res.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const validateNumberInput = (value: StoreValue): Promise<string | void> => {
    if (!value) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("VAT rate should not be empty");
    }
    if (value < 0 || value > 100) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("VAT rate should be between 0 and 100");
    }
    if (String(value).includes(".") && String(value).split(".")[1].length > 2) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject<string>("Only 2 decimal digits allowed for VAT input");
    }
    return Promise.resolve();
  };

  const switchVatBlock = (val: boolean) => {
    form.setFieldsValue({
      "vat-fees-apply": val,
      "vat-membership-apply": val,
      "vat-cardprod-fees-apply": val,
      "vat-trans-fees-apply": val,
      "vat-markup-fee-apply": val,
      "vat-other-fees-apply": val,
      "vat-late-fee-apply": val,
      "vat-ovl-fee-apply": val,
      "vat-nsf-fee-apply": val,
    });
  };

  return (
    <CustomFormWrapper
      pageTitle="Fee Settings"
      pageSubtitle="While the fees can have multiple values per each Pricing Control Table of the particular product, the following settings are applied universally to particular fee type for all products."
      form={form}
      submitHandler={onFinish as unknown as (values: FormValues) => void}
      formSize="lg"
      size="full"
      submitLabel="Save"
      relatedLinks={relatedLinks}
    >
      <PageItemLayoutGeneral size={16}>
        <PageItemLayoutGroup className={styles["tenant-fees-container"]}>
          <NetworkForm.DictionarySelect
            placeholder="Tenant country"
            filterOption={filterByDisplayValue}
            code={TENANT_COUNTRY}
            showSearch={true}
            formItemOptions={{
              name: "nic-domestic-country",
              label: "Domestic country",
              tooltip: {
                title:
                  'All the rest countries are considered to be "Foreign". These areas are used for transactional limits, IPS transactional fees, balance inquiry fees.',
                icon: <QuestionCircleFilled />,
              },
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "intareg-enabled",
              label: "Define intraregional area",
              tooltip: "This allows you to specify country codes to consider transaction as intraregional",
            }}
          />

          {isIntraregional && (
            <>
              <NetworkForm.DictionarySelect
                placeholder="Transaction fee countries"
                filterOption={filterByDisplayValue}
                code={TENANT_COUNTRY}
                showSearch={true}
                mode="multiple"
                formItemOptions={{
                  name: "nic-ips_trans_intrareg-countries",
                  label: "Intraregional countries for transaction fees",
                  tooltip:
                    "When intraregional countries are specified, these transactions will be posted in separate types for related fee setup",
                }}
              />

              <NetworkForm.DictionarySelect
                placeholder="Balance inquiry fees countries"
                filterOption={filterByDisplayValue}
                code={TENANT_COUNTRY}
                showSearch={true}
                mode="multiple"
                formItemOptions={{
                  name: "nic-bal-inq-fee-intrareg-pref-countries",
                  label: "Intraregional countries for balance inquiry fees",
                  tooltip:
                    "When intraregional countries are specified, these transactions will be posted in separate types for related fee setup",
                }}
              />
            </>
          )}
          <div className={styles["tenant-fees-section"]}>
            <div className={cn(styles["tenant-fees-form-item"], styles["tenant-fees-cb"])}>
              <NetworkForm.Switch
                onChange={switchVatBlock}
                formItemOptions={{ valuePropName: "checked", name: "vat-fees-apply" }}
                customLabel={
                  <TooltipInfo
                    label={<div className={styles["tenant-fees-text-cb"]}>Enable VAT (value added tax) on fees</div>}
                    code="vat-fees-apply"
                    tooltipProps={{}}
                  />
                }
              />
            </div>
            <PageItemLayoutElements>
              <div className={cn(styles["tenant-fees-form-item"], styles["item-margin-top"])}>
                <Form.Item dependencies={["vat-fees-apply"]}>
                  {() => (
                    <NetworkForm.Number
                      className={styles["tenant-fees-input-item"]}
                      disabled={!form.getFieldValue("vat-fees-apply")}
                      step="0.01"
                      stringMode={true}
                      precision={2}
                      formItemOptions={{
                        name: "vat-rate",
                        dependencies: ["vat-fees-apply"],
                        label: "Value added tax (VAT) rate, %",
                        rules: [
                          () => ({
                            validator(_, value) {
                              if (form.getFieldValue("vat-fees-apply")) return validateNumberInput(value);
                              return Promise.resolve();
                            },
                          }),
                        ],
                        tooltip:
                          "Used for applying on different types of fees. For fees setup configuring please visit Pricing control tables of the particular product",
                      }}
                    />
                  )}
                </Form.Item>
              </div>

              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <NetworkForm.Switch
                            disabled={!form.getFieldValue("vat-fees-apply")}
                            formItemOptions={{ valuePropName: "checked", name: "vat-membership-apply" }}
                            customLabel={
                              <TooltipInfo
                                label={
                                  <div className={styles["tenant-fees-text-cb"]}>
                                    Apply VAT on Membership fees, Joining fee
                                  </div>
                                }
                                code="vat-membership-apply"
                                tooltipProps={{}}
                              />
                            }
                          />
                        )}
                      </Form.Item>
                    </Space>
                  </div>
                )}
              </Form.Item>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <NetworkForm.Switch
                            disabled={!form.getFieldValue("vat-fees-apply")}
                            formItemOptions={{ valuePropName: "checked", name: "vat-cardprod-fees-apply" }}
                            customLabel={
                              <TooltipInfo
                                label={
                                  <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Card production fees</div>
                                }
                                code="vat-cardprod-fees-apply"
                                tooltipProps={{}}
                              />
                            }
                          />
                        )}
                      </Form.Item>
                    </Space>
                  </div>
                )}
              </Form.Item>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <NetworkForm.Switch
                            disabled={!form.getFieldValue("vat-fees-apply")}
                            formItemOptions={{ valuePropName: "checked", name: "vat-trans-fees-apply" }}
                            customLabel={
                              <TooltipInfo
                                label={
                                  <div className={styles["tenant-fees-text-cb"]}>
                                    Apply VAT on Transaction fees (Card transactions, Top-up, Payments)
                                  </div>
                                }
                                code="vat-trans-fees-apply"
                                tooltipProps={{}}
                              />
                            }
                          />
                        )}
                      </Form.Item>
                    </Space>
                  </div>
                )}
              </Form.Item>
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <NetworkForm.Switch
                            disabled={!form.getFieldValue("vat-fees-apply")}
                            formItemOptions={{ valuePropName: "checked", name: "vat-markup-fee-apply" }}
                            customLabel={
                              <TooltipInfo
                                label={<div className={styles["tenant-fees-text-cb"]}>Apply VAT on FX markup fee</div>}
                                code="vat-markup-fee-apply"
                                tooltipProps={{}}
                              />
                            }
                          />
                        )}
                      </Form.Item>
                    </Space>
                  </div>
                )}
              </Form.Item>
              {hasCreditProduct && (
                <>
                  <Form.Item dependencies={["vat-fees-apply"]}>
                    {() => (
                      <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                        <Space direction="horizontal">
                          <Form.Item dependencies={["vat-fees-apply"]}>
                            {() => (
                              <NetworkForm.Switch
                                disabled={!form.getFieldValue("vat-fees-apply")}
                                formItemOptions={{ valuePropName: "checked", name: "vat-late-fee-apply" }}
                                customLabel={
                                  <TooltipInfo
                                    label={
                                      <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Late payment fee</div>
                                    }
                                    code="vat-late-fee-apply"
                                    tooltipProps={{}}
                                  />
                                }
                              />
                            )}
                          </Form.Item>
                        </Space>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item dependencies={["vat-fees-apply"]}>
                    {() => (
                      <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                        <Space direction="horizontal">
                          <Form.Item dependencies={["vat-fees-apply"]}>
                            {() => (
                              <NetworkForm.Switch
                                disabled={!form.getFieldValue("vat-fees-apply")}
                                formItemOptions={{ valuePropName: "checked", name: "vat-ovl-fee-apply" }}
                                customLabel={
                                  <TooltipInfo
                                    label={
                                      <div className={styles["tenant-fees-text-cb"]}>Apply VAT on Overlimit fee</div>
                                    }
                                    code="vat-ovl-fee-apply"
                                    tooltipProps={{}}
                                  />
                                }
                              />
                            )}
                          </Form.Item>
                        </Space>
                      </div>
                    )}
                  </Form.Item>
                  <Form.Item dependencies={["vat-fees-apply"]}>
                    {() => (
                      <div className={cn(styles["tenant-fees-form-item"], styles["item-margin"])}>
                        <Space direction="horizontal">
                          <Form.Item dependencies={["vat-fees-apply"]}>
                            {() => (
                              <NetworkForm.Switch
                                disabled={!form.getFieldValue("vat-fees-apply")}
                                formItemOptions={{ valuePropName: "checked", name: "vat-nsf-fee-apply" }}
                                customLabel={
                                  <TooltipInfo
                                    label={
                                      <div className={styles["tenant-fees-text-cb"]}>
                                        Apply VAT on Not sufficient funds fee
                                      </div>
                                    }
                                    code="vat-nsf-fee-apply"
                                    tooltipProps={{}}
                                  />
                                }
                              />
                            )}
                          </Form.Item>
                        </Space>
                      </div>
                    )}
                  </Form.Item>
                </>
              )}
              <Form.Item dependencies={["vat-fees-apply"]}>
                {() => (
                  <div className={cn(styles["tenant-fees-form-item"], styles["item-margin-bot"])}>
                    <Space direction="horizontal">
                      <Form.Item dependencies={["vat-fees-apply"]}>
                        {() => (
                          <NetworkForm.Switch
                            disabled={!form.getFieldValue("vat-fees-apply")}
                            formItemOptions={{ valuePropName: "checked", name: "vat-other-fees-apply" }}
                            customLabel={
                              <TooltipInfo
                                label={
                                  <div className={styles["tenant-fees-text-cb"]}>
                                    Apply VAT on Statement fee, Inactivity fee, Balance inquiry fee, Decline fee
                                  </div>
                                }
                                code="vat-other-fees-apply"
                                tooltipProps={{}}
                              />
                            }
                          />
                        )}
                      </Form.Item>
                    </Space>
                  </div>
                )}
              </Form.Item>
            </PageItemLayoutElements>
          </div>
        </PageItemLayoutGroup>
        <PageItemLayoutGroup className={styles["tenant-fees-container"]}>
          <div className="text-blue-h3">Quasi cash</div>
          <div className={styles["section-description"]}>
            System identifies a transaction as Quasi cash transaction based on the transaction Merchant category code
            (MCC). Separate fees could be applied to this type of transaction.
          </div>
          <NetworkForm.Select
            mode="multiple"
            optionList={nicQuasiCashOptions}
            allowClear={true}
            formItemOptions={{
              name: "nic-quasi-cash",
              label: "Quasi cash MCC list",
              tooltip: {
                title:
                  "System identifies a transaction as Quasi cash transaction based on the transaction Merchant category code (MCC). E.g. '6050 - Quasi Cash, fin Inst', '6051 - Foreign Currency, Money, TC's'",
                icon: <QuestionCircleFilled />,
              },
              initialValue: quasiCleared ? [] : nicQuasiCashOptions.map(x => x.value),
            }}
          />
        </PageItemLayoutGroup>

        <PageItemLayoutGroup className={styles["tenant-fees-container"]}>
          <div className="text-blue-h3">Decline fee</div>
          <div className={styles["section-description"]}>
            Decline transaction fee is charged in case of any decline transactions as per response code list. Response
            code list can be defined in the system to consider the declined transactions.
          </div>
          <NetworkForm.Select
            mode="multiple"
            optionList={nicDeclTransFeeValPredefRcOptions}
            allowClear={true}
            formItemOptions={{
              name: "nic-decl-trans-fee-val-pref-rc",
              label: "Choose reason codes to apply decline fee",
              tooltip: {
                title: "Only selected decline reason codes will be charged",
                icon: <QuestionCircleFilled />,
              },
              initialValue: declineFeeCleared ? [] : nicDeclTransFeeValPredefRcOptions.map(x => x.value),
            }}
          />

          <NetworkForm.Switch
            formItemOptions={{ valuePropName: "checked", name: "nic-decl-trans-fee-val-pref-ex_bal_inq" }}
            customLabel={
              <TooltipInfo
                label={
                  <div className={styles["tenant-fees-text-cb"]}>Exclude declined balance inquiry from decline fee</div>
                }
                code="nic-decl-trans-fee-val-pref-ex_bal_inq"
                tooltipProps={{}}
              />
            }
          />
        </PageItemLayoutGroup>
      </PageItemLayoutGeneral>
    </CustomFormWrapper>
  );
};
