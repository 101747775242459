import { useEffect, useMemo, useState } from "react";

import { useReduxState } from "@ni/common/hooks";
import { Product } from "@ni/sdk/models";

import { sortFunctions } from "../../constants";

interface Filters {
  search?: string;
  sort?: "asc" | "desc" | "asc_ec" | "desc_ec";
}

const useClientPct = () => {
  const [currentProduct] = useReduxState<Product>("currentProduct");

  const [filters, setFilters] = useState<Filters>({});
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const { paginatedData, dataSource } = useMemo(() => {
    let dataSource = (currentProduct?.parameterTables ?? [])
      .slice()
      .sort((a, b) => (a.id ?? 0) - (b.id ?? 0))
      .map((item, index) => ({
        ...item,
        externalCode: item.externalCode || String(index + 1).padStart(3, "0"),
      }));

    const searchQuery = filters.search?.toLowerCase().trim();
    if (searchQuery) {
      dataSource = dataSource.filter(item => item.displayName?.toLowerCase().includes(searchQuery));
    }

    if (filters.sort && filters.sort in sortFunctions) {
      dataSource = dataSource.sort(sortFunctions[filters.sort]);
    }

    const startIndex = Math.max(0, (currentPage - 1) * pageSize);
    const paginatedData = dataSource.slice(startIndex, startIndex + pageSize);

    return {
      paginatedData,
      dataSource,
    };
  }, [currentProduct?.parameterTables, filters, currentPage, pageSize]);

  useEffect(() => {
    const totalPages = Math.ceil(dataSource.length / pageSize);
    if (currentPage > totalPages && totalPages > 0) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, dataSource.length, pageSize]);

  return { paginatedData, dataSource, setFilters, currentPage, setCurrentPage, pageSize, setPageSize };
};

export { useClientPct };
