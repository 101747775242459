import React, { FC } from "react";
import { notification, Table, TableColumnType, TablePaginationConfig, Tooltip, Typography } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import { useNavigate } from "react-router-dom";

import { CloudUploadOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { EMPTY_DATA_FLAG } from "@ni/common/constants";
import { useReduxState } from "@ni/common/hooks";
import { ActionsCell } from "@ni/common/ui";
import { dateFormat } from "@ni/common/utils";
import {
  Deployment,
  DeploymentsState,
  DeploymentsState as DeploymentsStateType,
  Order,
  SortedFilteredPageRequest,
} from "@ni/sdk/models";

import { DEPLOYMENT_STATE_COLORS } from "../../constants";

import styles from "./styles.module.scss";

interface DeploymentsTableViewProps {
  deploymentList: Deployment[];
  pagination: TablePaginationConfig;
  setPagination: React.Dispatch<React.SetStateAction<TablePaginationConfig>>;
  setDeploymentsFilters: React.Dispatch<React.SetStateAction<SortedFilteredPageRequest>>;
  fetchDepeloymentById: (id: number) => void;
  reTryDeployment: (deploymentId: number) => void;
}

const { Text } = Typography;

export const DeploymentsTable: FC<DeploymentsTableViewProps> = ({
  deploymentList,
  pagination,
  setPagination,
  setDeploymentsFilters,
  fetchDepeloymentById,
  reTryDeployment,
}) => {
  const navigate = useNavigate();

  const [isLoading] = useReduxState("isLoading", false);

  const onRowHandler = (item: Deployment) => {
    return {
      onDoubleClick: () => navigate(`${item.id}`),
    };
  };

  const handleChange = (
    pagination: TablePaginationConfig,
    _: Record<string, FilterValue | null>,
    sorter: SorterResult<Deployment> | SorterResult<Deployment>[],
  ) => {
    let newSorting: Array<Order> = [];
    if (Object.keys(sorter).length) {
      const sorterArray = Array.isArray(sorter) ? sorter : [sorter];

      newSorting = sorterArray.map(sorterItem => {
        const { order, columnKey } = sorterItem;

        if (!order) {
          return undefined;
        }

        return {
          direction: order === "ascend" ? "ASC" : order === "descend" ? "DESC" : "",
          property: columnKey,
        };
      }) as Array<Order>;
    }

    setPagination(pagination);

    setDeploymentsFilters(existingFilters => ({
      ...existingFilters,
      sorting: newSorting.filter(Boolean),
    }));
  };

  const onCopy = (target: string, text: string) => {
    navigator.clipboard
      .writeText(target)
      .then(() => {
        notification.success({
          placement: "topRight",
          duration: 5,
          message: "Success",
          description: `${text} copied`,
        });
      })
      .catch(() => {
        notification.error({
          placement: "topRight",
          duration: 5,
          message: "Something went wrong, could not copy.",
        });
      });
  };

  const getActionItems = (deploymentsState: DeploymentsStateType) => {
    const actions = [
      {
        label: "Refresh",
        icon: <RedoOutlined />,
        actionCallBack: (item: Deployment) => {
          fetchDepeloymentById(item.id!);
        },
      },
      {
        label: "View details",
        icon: <SearchOutlined />,
        actionCallBack: (item: Deployment) => {
          navigate(`${item.id}`);
        },
      },
    ];

    if (deploymentsState === DeploymentsState.FAILURE) {
      actions.push({
        label: "Retry deployment",
        icon: <CloudUploadOutlined />,
        actionCallBack: (item: Deployment) => {
          reTryDeployment(Number(item.id));
        },
      });
    }
    return actions;
  };

  const columns: TableColumnType<Deployment>[] = [
    {
      title: "FI Code",
      dataIndex: ["tenantExternalCode"],
      key: "tenantExternalCode",
      width: "4%",
      render: (_: string, { tenantExternalCode }: Deployment) => tenantExternalCode ?? EMPTY_DATA_FLAG,
    },
    {
      title: "Tenant",
      dataIndex: ["tenantName"],
      key: "tenantName",
      width: "9%",
      ellipsis: true,
      render: (_: string, { tenantName }: Deployment) => tenantName ?? EMPTY_DATA_FLAG,
    },
    {
      title: "Product",
      dataIndex: ["productName"],
      key: "productName",
      width: "15%",
      ellipsis: true,
      render: (_: string, item: Deployment) => item?.productName,
    },
    {
      title: "Status",
      dataIndex: ["state"],
      key: "state",
      width: "6%",
      ellipsis: true,
      render: (_: string, item: Deployment) => {
        const stagesStatusSet = new Set(item?.deploymentDetails?.map(details => details.stageState));
        let leftState: string | null = null;
        if (stagesStatusSet.delete("SUCCESS"))
          leftState = Object.values(stagesStatusSet)?.length > 0 ? (Object.values(stagesStatusSet)[0] as string) : null;
        return item?.state ? (
          <Text type={DEPLOYMENT_STATE_COLORS[item.state]}>
            {item.state === "SUCCESS" && leftState ? leftState : item.state}
          </Text>
        ) : (
          EMPTY_DATA_FLAG
        );
      },
    },
    {
      title: "Start time",
      dataIndex: ["startTime"],
      key: "startTime",
      width: "9%",
      ellipsis: true,
      sorter: (a, b) => Number(a.startTime) - Number(b.startTime),
      render: (_: string, { startTime }: Deployment) => (startTime ? dateFormat(startTime) : EMPTY_DATA_FLAG),
    },
    {
      title: "Jira ticket ID",
      dataIndex: ["jiraTicket"],
      key: "jiraTicket",
      width: "6%",
      ellipsis: true,
      render: (_: string, item: Deployment) => (
        <Tooltip title="Copy Jira ticket ID" placement="bottom">
          <Typography.Text
            ellipsis={true}
            onClick={() => onCopy(item.jiraTicket as string, "Jira ticket ID")}
            role="presentation"
          >
            {item.jiraTicket}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "Additional notes",
      dataIndex: ["additionalNotes"],
      key: "additionalNotes",
      width: "8%",
      ellipsis: true,
      render: (_: string, item: Deployment) => (
        <Tooltip title="Copy additional notes" placement="bottom">
          <Typography.Text
            ellipsis={true}
            onClick={() => onCopy(item.additionalNotes as string, "Additional note")}
            role="presentation"
          >
            {item.additionalNotes}
          </Typography.Text>
        </Tooltip>
      ),
    },
    {
      title: "",
      key: "id",
      width: "2.5%",
      ellipsis: true,
      render: (_: string, item: Deployment) => (
        <ActionsCell items={getActionItems(item.state as DeploymentsStateType)} rowData={item} />
      ),
    },
  ];

  return (
    <Table<Deployment>
      rowKey="id"
      tableLayout="fixed"
      className={styles["ni-table"]}
      columns={columns}
      pagination={pagination}
      dataSource={deploymentList}
      onRow={onRowHandler}
      onChange={handleChange}
      loading={isLoading}
    />
  );
};
