import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Flex, Form, FormInstance, Table, Tooltip, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Link } from "react-router-dom";

import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { defaultScheme, mc, upi, visa } from "@ni/common/assets";
import { useGetPaymentScheme, useReduxState } from "@ni/common/hooks";
import { FormValues } from "@ni/common/types";
import { checkIfProductCreatedSuccessfully, onKeyDownPreventChars, onPastePreventChars } from "@ni/common/utils";
import { Tenant } from "@ni/sdk/models";

import { NetworkForm } from "../FormInput";
import { FormItemLabel } from "../Labels";
import { SearchableDropdown } from "../SearchableDropdown";
import { TooltipInfo } from "../TooltipInfo";

import styles from "./styles.module.scss";

interface SchemeOption {
  key: string;
  label: string;
  value: string;
}

interface Props {
  form: FormInstance<FormValues>;
  type?: "AFFILIATED" | "PRINCIPAL";
  tenant?: Tenant;
  disabled?: boolean;
}

const PaymentSchemeTable = ({ form, type = "PRINCIPAL", tenant, disabled = false }: Props) => {
  const [schemes, setSchemes] = useState<SchemeOption[]>([]);
  const [isLoading] = useReduxState<boolean>("isLoading");

  const { schemeList } = useGetPaymentScheme();

  const formPaymentSchemes = Form.useWatch("payment-scheme", form) as string;
  const initialized = useRef<boolean>(false);

  useEffect(() => {
    const tenantValues = tenant?.tenantValues;
    if (!tenantValues || !schemeList.length || initialized.current) return;

    const tenantPaymentSchemes = tenantValues.find(x => x.fieldCode === "payment-scheme")?.value;
    if (!formPaymentSchemes && tenantPaymentSchemes) {
      form.setFieldValue("payment-scheme", tenantPaymentSchemes);
    }

    const paymentSchemes = tenantPaymentSchemes?.split(",");
    paymentSchemes?.forEach(scheme => {
      const memberId = tenantValues.find(x => x.fieldCode === `${scheme}-member-id`)?.value;
      const principalId = tenantValues.find(x => x.fieldCode === `${scheme}-principal_idn`)?.value;
      const getScheme = schemeList.find(x => x.value?.toLowerCase() === scheme?.toLowerCase());

      if (getScheme) {
        setSchemes(prev => {
          if (!prev.some(scheme => scheme.value === getScheme.value)) {
            return [
              ...prev,
              {
                key: getScheme.value,
                label: getScheme.displayValue ?? "",
                value: getScheme.value,
              },
            ];
          }
          return prev;
        });
      }

      initialized.current = true;
      form.setFieldsValue({ [`${scheme}-member-id`]: memberId, [`${scheme}-principal_idn`]: principalId });
    });
  }, [form, formPaymentSchemes, schemeList, tenant?.tenantValues]);

  useEffect(() => {
    form.setFieldValue("payment-scheme", schemes.map(x => x.value.toLowerCase()).join(","));
    void form.validateFields([
      "payment-scheme",
      ...schemes.map(x => [`${x.key?.toLowerCase()}-member-id`, `${x.key?.toLowerCase()}-principal_idn`]).flat(),
    ]);
  }, [form, formPaymentSchemes, schemes]);

  const getProductMiniIps = useCallback((ips: string) => {
    if (ips === "Visa") return visa;
    if (ips === "MC") return mc;
    if (ips === "UnionPay") return upi;
    return defaultScheme;
  }, []);

  const columns = useMemo(() => {
    const columns: ColumnsType<SchemeOption> = [
      {
        key: "scheme",
        dataIndex: "label",
        title: "Scheme",
        width: "25%",
        render: (text: string, record: SchemeOption) => (
          <Flex justify="space-between">
            <TooltipInfo label={text} code={record.value.toLowerCase()} tooltipProps={{ title: undefined }} />
            <img alt="mini-ips" src={getProductMiniIps(record.value)} />
          </Flex>
        ),
      },
      {
        key: "memberId",
        title: "Member ID",
        width: "25%",
        render: (_: string, record: SchemeOption) => {
          return !disabled ? (
            <FormItemLabel code={`${record.value?.toLowerCase()}-member-id`}>
              <NetworkForm.String
                formItemOptions={{
                  name: `${record.value?.toLowerCase()}-member-id`,
                  rules: [{ required: true, message: `Member ID is required for ${record.label}` }],
                  noStyle: true,
                }}
                size="small"
                maxLength={6}
                onKeyDown={onKeyDownPreventChars}
                onPaste={onPastePreventChars}
                disabled={disabled}
                className={styles["payment-scheme-input"]}
              />
            </FormItemLabel>
          ) : (
            <div style={{ padding: "0 8px" }}>
              <TooltipInfo
                label={form.getFieldValue(`${record.value?.toLowerCase()}-member-id`) as string}
                code={`${record.value?.toLowerCase()}-member-id`}
                tooltipProps={{}}
              />
            </div>
          );
        },
      },
    ];

    if (type === "AFFILIATED") {
      columns.push({
        key: "principalId",
        width: "25%",
        title: (
          <TooltipInfo
            label="Principal ID"
            tooltipProps={{
              title:
                "This parameter specifies the Principal Financial Institution ID, it will be used to Generate Quarterly Matrix Report.",
            }}
          />
        ),
        render: (_: string, record: SchemeOption) => {
          return !disabled ? (
            <FormItemLabel code={`${record.value?.toLowerCase()}-principal_idn`}>
              <NetworkForm.String
                formItemOptions={{
                  name: `${record.value?.toLowerCase()}-principal_idn`,
                  // this rule is not required for now - will be added later
                  // rules: [{ required: true, message: `Principal ID is required for ${record.label}` }],
                  noStyle: true,
                }}
                size="small"
                maxLength={6}
                onKeyDown={onKeyDownPreventChars}
                onPaste={onPastePreventChars}
                disabled={disabled}
                className={styles["payment-scheme-input"]}
              />
            </FormItemLabel>
          ) : (
            <div style={{ padding: "0 8px" }}>
              <TooltipInfo
                label={form.getFieldValue(`${record.value?.toLowerCase()}-principal_idn`) as string}
                code={`${record.value?.toLowerCase()}-principal_idn`}
                tooltipProps={{}}
              />
            </div>
          );
        },
      });
    }

    if (!disabled) {
      columns.push({
        key: "delete",
        width: "2.5%",
        align: "center" as const,
        render: (_: string, record: SchemeOption) => {
          const matchingProducts = tenant?.products?.filter(product =>
            product.productValues?.some(
              ({ fieldCode, value }) =>
                fieldCode === "nic-prod-ips" && value?.toLowerCase() === record.value?.toLowerCase(),
            ),
          );

          const isDisabled = !!matchingProducts?.length;

          const renderTooltip = () => {
            if (isDisabled) {
              return (
                <Flex vertical={true}>
                  <p className="m-b-8">This tenant has products linked to this payment scheme.</p>
                  <p className="m-b-8">
                    To delete the scheme, update the products payment scheme or remove the products.
                  </p>
                  <p className="m-b-8">Linked products:</p>
                  <ul className="m-b-0">
                    {matchingProducts.map(x => {
                      const href = checkIfProductCreatedSuccessfully(x.lastProcessedPage?.code as string)
                        ? `/tenant/${tenant?.id}/product/${x.id}/product-details`
                        : `/create-product?tenantId=${tenant?.id}&productId=${x.id}`;

                      return (
                        <li key={x.id}>
                          <Link to={href}>{x.displayName}</Link>
                        </li>
                      );
                    })}
                  </ul>
                </Flex>
              );
            }

            return "Delete";
          };

          return (
            <Tooltip title={renderTooltip()} placement="right">
              <Button
                type="text"
                htmlType="button"
                icon={<DeleteOutlined />}
                className="text-primary"
                onClick={() => {
                  form.resetFields([`${record.value?.toLowerCase()}-member-id`]);
                  setSchemes(prev => prev.filter(x => x.value !== record.value));
                }}
                disabled={disabled || isDisabled}
                tabIndex={-1}
              />
            </Tooltip>
          );
        },
      });
    }

    return columns;
  }, [type, disabled, getProductMiniIps, form, tenant?.products, tenant?.id]);

  const availablePaymentSchemes = useMemo(
    () => [
      { key: "all", label: "Add All", value: "all" },
      ...(schemeList
        .filter(dic => !schemes.some(({ value }) => value === dic.value))
        .map(item => ({
          key: item.value,
          label: item.displayValue,
          value: item.value,
        })) as SchemeOption[]),
    ],
    [schemeList, schemes],
  );

  const onClickMenu = useCallback(
    ({ key }: { key: string }) => {
      if (key && key === "all") {
        setSchemes(
          schemeList.map(x => ({
            key: x.value,
            label: x.displayValue ?? "",
            value: x.value,
          })),
        );
      } else {
        const selectedScheme = schemeList.find(({ value }) => value === key);

        if (selectedScheme) {
          setSchemes(prev => [
            ...prev,
            {
              key: selectedScheme.value,
              label: selectedScheme.displayValue ?? "",
              value: selectedScheme.value,
            },
          ]);
        }
      }
    },
    [schemeList],
  );

  return (
    <Form.Item
      name="payment-scheme"
      label={
        <TooltipInfo
          label="Payment scheme"
          code="payment-scheme"
          tooltipProps={{
            title: "This parameter specifies the payment scheme that the Financial institution is associated with",
          }}
        />
      }
      rules={[{ required: true, message: "Payment scheme is required" }]}
      className={styles["payment-scheme-form"]}
    >
      <Table
        size="small"
        rowKey="key"
        dataSource={schemes}
        columns={columns}
        loading={isLoading}
        bordered={true}
        pagination={false}
        className={styles["payment-scheme-table"]}
        footer={() => (
          <Flex justify="space-between" align="center">
            <Typography.Text strong={true} style={{ color: "#8a8a8d", fontSize: "12px" }}>
              Each payment scheme requires a unique member ID
            </Typography.Text>

            {!disabled && (
              <SearchableDropdown
                placement="top"
                trigger={["click"]}
                menu={{ onClick: onClickMenu }}
                options={availablePaymentSchemes}
              >
                <Button type="primary" icon={<PlusOutlined />} className={styles["add-button"]}>
                  Add scheme
                </Button>
              </SearchableDropdown>
            )}
          </Flex>
        )}
      />
    </Form.Item>
  );
};

export { PaymentSchemeTable };
