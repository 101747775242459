import { useMemo } from "react";
import { Flex, Typography } from "antd";
import { Link } from "react-router-dom";

import { InfoCircleOutlined } from "@ant-design/icons";
import { SelectOption } from "@ni/common/types";
import { Dictionary, Tenant } from "@ni/sdk/models";

import { NetworkForm } from "../FormInput";
import { NetworkSelectProps } from "../FormInput/Select";

interface Props extends NetworkSelectProps {
  tenant?: Tenant;
  schemeList: Dictionary[];
}

const PaymentScheme = ({ tenant, schemeList, ...props }: Props) => {
  const availablePaymentSchemes = useMemo(() => {
    const tenantSchemes = tenant?.tenantValues
      ?.find(({ fieldCode }) => fieldCode === "payment-scheme")
      ?.value?.split(",");

    return tenantSchemes?.map(key => {
      const scheme = schemeList.find(x => x.value.toLowerCase() === key.toLowerCase());

      return {
        key,
        label: scheme?.displayValue,
        value: scheme?.value,
      } as SelectOption;
    });
  }, [schemeList, tenant?.tenantValues]);

  return (
    <NetworkForm.Select
      formItemOptions={{
        name: "nic-prod-ips",
        label: "Payment scheme",
        rules: [{ required: true, message: "Payment scheme is required!" }],
      }}
      {...props}
      optionList={availablePaymentSchemes}
      notFoundContent={
        <Flex justify="center" align="center" vertical={true} gap={8} style={{ padding: "8px 0" }}>
          <InfoCircleOutlined style={{ fontSize: 24 }} />
          <Typography.Paragraph type="secondary" style={{ textAlign: "center", marginBottom: 0 }}>
            No payment schemes are linked to this product. Add them via the{" "}
            <Link to={`/tenant/${tenant?.id ?? 0}/details`}>Tenant Details</Link> page.
          </Typography.Paragraph>
        </Flex>
      }
    />
  );
};

export { PaymentScheme };
