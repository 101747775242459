import { FC, useCallback, useMemo, useState } from "react";
import { Button, Col, Form, Input, Modal, notification, Typography } from "antd";
import { Link, useNavigate } from "react-router-dom";

import { BlockOutlined, CreditCardOutlined, DeleteOutlined, TableOutlined, TagOutlined } from "@ant-design/icons";
import { useReduxState } from "@ni/common/hooks";
import { paymentMethodKeyToValueMapper } from "@ni/common/mocks";
import { DashboardTenantUI, IJiraCommitForm } from "@ni/common/types";
import { ActionsCell, CardView, JiraCommitForm, MenuItemModel } from "@ni/common/ui";
import { checkIfProductCreatedSuccessfully, getErrorInstance } from "@ni/common/utils";
import { DeploymentsApi, ProductApi } from "@ni/sdk/apis";
import {
  DashboardProduct,
  ProductState,
  RoleRestrictionsAccessLevel,
  RoleRestrictionsObjectLevel,
  Tenant,
  UserFull,
} from "@ni/sdk/models";

import { useDashboard } from "../../hooks";

import styles from "./styles.module.scss";

interface ProductBlockProperties {
  tenant: Tenant;
  product: DashboardProduct;
  onDuplicateSuccess: () => void;
}

interface DuplicateProductForm {
  newDisplayName: string;
}

const productServicesApi = new ProductApi();
const deploymentsApi = new DeploymentsApi();

export const CardBlock: FC<ProductBlockProperties> = props => {
  const navigate = useNavigate();

  const [modalForm] = Form.useForm();
  const [jiraForm] = Form.useForm<IJiraCommitForm>();

  const [user] = useReduxState<UserFull>("user");
  const [, setDashboardCards] = useReduxState<DashboardTenantUI[]>("dashboardCards", []);
  const [, setTenantRowkey] = useReduxState<string | undefined>("tenantRowkey");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalOpened, setModalOpened] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [sandboxModalOpened, setSandboxModalOpened] = useState<boolean>(false);
  const [modalId, setModalId] = useState<string | undefined>();

  const { tenant, product, onDuplicateSuccess } = props;
  const { getCardTenants } = useDashboard();

  const { isReadyToUat, isReadyToReview, isCreationCompleted } = useMemo(
    () => ({
      isAdmin: !!user?.userRoles
        ?.flatMap(x => x?.roleRestrictions)
        ?.find(
          x =>
            x?.restrictionsObjectLevel === RoleRestrictionsObjectLevel.APPLICATION &&
            x?.restrictionsAccessLevel === RoleRestrictionsAccessLevel.MANAGE,
        ),
      isReadyToUat: product.productState === ProductState.DRAFT,
      isReadyToReview: product.productState === ProductState.PENDING_CHANGES,
      isCreationCompleted: checkIfProductCreatedSuccessfully(product?.lastProcessedPage?.code as string),
    }),
    [product?.lastProcessedPage?.code, product.productState, user?.userRoles],
  );

  const paymentMethodsMapper = paymentMethodKeyToValueMapper as { [key: string]: string };

  const onDuplicateProduct = (formData: DuplicateProductForm): void => {
    setIsLoading(true);
    productServicesApi
      .copyProduct(formData, parseInt(modalId as string, 10))
      .then(() => {
        setIsLoading(false);
        setModalOpened(false);
        onDuplicateSuccess();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const onCancelModal = (): void => {
    setModalOpened(false);
  };

  const onUpdateProductState = useCallback(
    (params?: { id: string; tenantId: string; label: ProductState }) => {
      setIsLoading(true);

      productServicesApi
        .editProduct({ productState: params?.label ?? ProductState.DRAFT }, parseInt(params?.id ?? modalId ?? "0", 10))
        .then(({ data }) => {
          setDashboardCards(
            prev =>
              prev.map(tenant =>
                tenant.id === Number(params?.tenantId ?? tenant.id)
                  ? {
                      ...tenant,
                      products: tenant.products.map(product =>
                        product.id === Number(params?.id ?? modalId) ? { ...product, ...data } : product,
                      ),
                    }
                  : tenant,
              ) as DashboardTenantUI[],
          );

          notification.success({
            placement: "topRight",
            duration: 5,
            message: "Success",
            description: `Product state has been changed to ${params?.label}`,
          });
        })
        .catch(() => {})
        .finally(() => {
          setIsLoading(false);
          setModalId(undefined);
        });
    },
    [modalId, setDashboardCards],
  );

  const actionsItems = useMemo(() => {
    const items: MenuItemModel[] = [
      {
        label: "Product settings",
        icon: <CreditCardOutlined />,
        disabled: !isCreationCompleted,
        link: `/tenant/${tenant?.id}/product/${product.id}/product-details`,
      },
      {
        label: "Pricing control tables",
        icon: <TableOutlined />,
        disabled: !isCreationCompleted,
        link: `/tenant/${tenant?.id}/product/${product.id}/pct/`,
      },
      {
        label: "Duplicate product",
        icon: <BlockOutlined />,
        disabled: !isCreationCompleted,
        actionCallBack: (data: { id: string; tenantId: string }) => {
          const { id } = data;

          setModalId(id);
          modalForm.setFieldsValue({
            newDisplayName: `Copy of ${product.name || ""}`,
          });

          setModalOpened(true);
        },
      },
      {
        label: "Change status",
        icon: <TagOutlined />,
        disabled: !isCreationCompleted,
        children: Object.values(ProductState)
          .filter(state => state !== "DELETED")
          .map(state => ({ label: state, actionCallBack: onUpdateProductState })),
      },
      ...(product.productState === ProductState.DRAFT
        ? [
            {
              label: "Delete product",
              icon: <DeleteOutlined />,
              actionCallBack: ({ id }: { id: string }) => {
                setModalId(id);
                setDeleteModalOpen(true);
              },
            },
          ]
        : []),
    ];

    return items;
  }, [
    isCreationCompleted,
    modalForm,
    onUpdateProductState,
    product.id,
    product.name,
    product.productState,
    tenant?.id,
  ]);

  const onDeleteDraftProduct = () => {
    setIsLoading(true);

    productServicesApi
      .deleteProductById(Number(modalId))
      .then(() => {
        setDashboardCards(prev =>
          prev.map(prevTenant =>
            prevTenant?.id === Number(tenant?.id)
              ? { ...prevTenant, products: prevTenant.products.filter(product => product.id !== Number(modalId)) }
              : prevTenant,
          ),
        );

        notification.success({
          placement: "topRight",
          duration: 5,
          message: "Success",
          description: "Product has been deleted.",
        });
      })
      .catch(response => {
        const errorInstance = getErrorInstance(response);
        notification.error({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {errorInstance?.response.status} <br />
              {errorInstance?.response.data.errorMessage}
            </div>
          ),
        });
      })
      .finally(() => {
        setDeleteModalOpen(false);
        setIsLoading(false);
        setModalId(undefined);
      });
  };

  const continueProductCreation = (): void => {
    const queries = new URLSearchParams({
      tenantId: String(tenant?.id),
      productId: String(product.id),
    });

    navigate({
      pathname: `/create-product`,
      search: queries.toString(),
    });
  };

  const goToUATHandle = (): void => {
    void jiraForm
      .validateFields()
      .then(formValues => {
        setIsLoading(true);

        void deploymentsApi
          .put1({
            productId: product.id,
            state: "IN_PROGRESS",
            jiraTicket: formValues.jiraTicketId.join(","),
            additionalNotes: formValues.commitComment,
            tenantExternalCode: formValues?.fiCode,
            branchName: formValues?.destinationBranch,
            originBranchName: formValues?.originBranch,
            featureBranchName: formValues?.sourceBranch,
            deploymentDetails: [],
          })
          .then(jiraResponse => {
            if (jiraResponse?.data) {
              const id = product?.id;
              productServicesApi
                .confirmUat(id)
                .then(response => {
                  if (Number.isInteger(response?.data?.id)) {
                    setIsLoading(false);
                    setSandboxModalOpened(false);
                    void getCardTenants({});
                  } else {
                    throw new Error();
                  }
                })
                .catch(response => {
                  const errorInstance = getErrorInstance(response);
                  notification.error({
                    placement: "topRight",
                    duration: 5,
                    message: (
                      <div>
                        {errorInstance?.response?.status} <br />
                        {errorInstance?.response?.data?.errorMessage}
                      </div>
                    ),
                  });
                  setIsLoading(false);
                  setSandboxModalOpened(false);
                });
            } else {
              setIsLoading(false);
              notification.error({ message: "Something went wrong, please try again later!" });
            }
          });
      })
      .catch(() => {});
  };

  const uatDryRun = () => {
    setIsLoading(true);
    const id = product?.id;
    productServicesApi
      .uatDryRun(id)
      .then(response => {
        if (Number.isInteger(response?.data?.id)) {
          setTenantRowkey(tenant.externalCode);

          notification.warning({
            placement: "topRight",
            duration: 10,
            message: "Success",
            description: "Build configuration process for this product has been started.",
          });

          const redirectToQp = setTimeout(() => {
            navigate("/admin/questionnaire-processing-confirmation");
            clearTimeout(redirectToQp);
          }, 10000);

          void getCardTenants({});
        } else {
          throw new Error();
        }
      })
      .catch(response => {
        const errorInstance = getErrorInstance(response);
        notification.error({
          placement: "topRight",
          duration: 5,
          message: (
            <div>
              {errorInstance?.response.status} <br />
              {errorInstance?.response.data.errorMessage}
            </div>
          ),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getBalanceOwner = (balanceOwner?: string): string => {
    if (!balanceOwner) {
      return "-";
    }

    return balanceOwner === "CMS" ? "Network" : "Tenant";
  };

  return (
    <Col sm={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8, offset: "16px" }} xxl={{ span: 6, offset: "16px" }}>
      <div className={styles["tenant-product-card"]}>
        <div className={styles["tenant-product-card-heading"]}>
          <div className={styles["tenant-product-card-name"]}>{product.name}</div>
          <div className={styles["tenant-product-card-options"]}>
            <ActionsCell items={actionsItems} rowData={{ id: product.id?.toString(), tenantId: tenant?.id }} />
          </div>
        </div>
        <div className={styles["tenant-product-card-body"]}>
          <div className={styles["tenant-product-card-image"]}>
            {isCreationCompleted ? (
              <Link to={`/tenant/${tenant?.id}/product/${product.id || ""}/product-details`}>
                <CardView
                  className={isCreationCompleted ? "" : "card-disabled"}
                  cardImage={product.cardImage}
                  prodIps={product.paymentScheme}
                />
              </Link>
            ) : (
              <CardView
                className={isCreationCompleted ? "" : "card-disabled"}
                cardImage={product.cardImage}
                prodIps={product.paymentScheme}
              />
            )}
            <div hidden={!isReadyToUat}>
              <Button
                loading={isLoading}
                onClick={() => (isCreationCompleted ? setSandboxModalOpened(true) : continueProductCreation())}
              >
                {isCreationCompleted ? "Test in sandbox" : "Continue creating"}
              </Button>
            </div>
            <div hidden={!isReadyToReview}>
              <Button loading={isLoading} onClick={uatDryRun}>
                Build configuration
              </Button>
            </div>
          </div>

          <div className={styles["tenant-product-card-data"]}>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>State</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["grayed-div"]}>{product.productState}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Product</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.productType || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Payment Scheme</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>
                  {paymentMethodsMapper[product.paymentScheme as string] || "-"}
                </div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Co-badge scheme</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.coBadgeScheme || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>BIN</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.bin || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Currency</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{product.currency || "-"}</div>
              </div>
            </div>
            <div className={styles["tenant-product-card-data-row"]}>
              <div className={styles["tenant-product-card-data-column"]}>
                <div className={styles["tenant-product-card-data-label"]}>Balance</div>
              </div>
              <div className={styles["tenant-product-card-data-column-val"]}>
                <div className={styles["tenant-product-card-data-value"]}>{getBalanceOwner(product.balanceOwner)}</div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          title="Confirmation"
          open={deleteModalOpen}
          width="600px"
          onCancel={() => setDeleteModalOpen(false)}
          footer={[
            <Button key="back" onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={onDeleteDraftProduct}>
              Confirm
            </Button>,
          ]}
        >
          <Typography.Text>Are you sure you want to delete this product? This action is irreversible.</Typography.Text>
        </Modal>

        <Modal
          title="You are about to apply changes for this FI set up! A valid Jira tickets ID are required to proceed, ready to continue?"
          open={sandboxModalOpened}
          width="500px"
          onCancel={() => setSandboxModalOpened(false)}
          footer={[
            <Button key="back" onClick={() => setSandboxModalOpened(false)}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={goToUATHandle}>
              Confirm
            </Button>,
          ]}
        >
          <JiraCommitForm form={jiraForm} />
        </Modal>

        <Modal
          title="Copy of product will be created"
          open={modalOpened}
          width="600px"
          onCancel={onCancelModal}
          footer={[
            <Button key="back" onClick={onCancelModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isLoading} onClick={modalForm.submit}>
              Confirm
            </Button>,
          ]}
        >
          <Form form={modalForm} layout="vertical" onFinish={onDuplicateProduct}>
            <Form.Item
              name="newDisplayName"
              label="Provide the name for the new product"
              rules={[{ type: "string", max: 256, message: " Cannot be empty. Max length is 256 letters" }]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </Col>
  );
};
