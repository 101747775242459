import { FC, ReactNode, useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { ErrorSection, notification } from "ni-ui-kit";
import { useLocation, useNavigate } from "react-router-dom";

import { errorCodes, errorDetails } from "./constants";

type ErrorObject = {
  code: string;
  title: string;
  message: string;
};

const boundaryUriSkipList = ["/qp", "/imports", "/default-block-codes", "/parameters-table"] as const;

export const ErrorBoundary: FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<ErrorObject | null>(null);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const errorHandler = (
      err: AxiosError<{
        status: number;
        errorMessage: string;
        statusCode: number;
      }>,
    ) => {
      if (err?.response?.status && errorCodes.includes(err?.response?.status)) {
        if (err?.response && err.response?.data?.statusCode) {
          let shouldSkip = false;
          boundaryUriSkipList.forEach(uri => {
            if (err.response?.config?.url?.includes(uri)) {
              shouldSkip = true;
            }
          });
          if (!shouldSkip) {
            setError(prev =>
              prev === null
                ? {
                    code: String(err?.response?.status),
                    title: errorDetails[err?.response?.status ?? 0].title,
                    message:
                      (errorDetails[err?.response?.status ?? 0].message || err?.response?.data?.errorMessage) ?? "",
                  }
                : prev,
            );
          } else {
            notification.error({
              message: `${err?.response?.status} ${errorDetails[err?.response?.status ?? 0].title}`,
              description: `${(err?.response?.data?.errorMessage || errorDetails[err?.response?.status ?? 0].message) ?? ""}`,
            });
          }
        }
      }
    };

    const interceptor = axios.interceptors.response.use(undefined, errorHandler);

    return () => {
      setError(null);
      axios.interceptors.response.eject(interceptor);
    };
  }, [location]);

  return error ? (
    <ErrorSection code={error.code} title={error.title} message={error.message} onClick={() => navigate("/")} />
  ) : (
    children
  );
};
