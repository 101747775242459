import { FC, useCallback, useMemo } from "react";
import { Form, Typography } from "antd";
import { useParams } from "react-router-dom";

import { QuestionCircleFilled } from "@ant-design/icons";
import { PRODUCT_CURRENCY } from "@ni/common/constants";
import {
  useGetPaymentScheme,
  useHydrateForm,
  useProductSettings,
  useProductStateFormDisabled,
  useReduxState,
  useToggleFeature,
} from "@ni/common/hooks";
import { productTypeOptionsWithKey } from "@ni/common/mocks";
import { FormValues } from "@ni/common/types";
import { CardView, CustomFormWrapper, NetworkForm, PaymentScheme, TooltipInfo } from "@ni/common/ui";
import { filterByDisplayValue, getFormValueFromProductValues } from "@ni/common/utils";
import { Tenant } from "@ni/sdk/models";

import { creditInterestType, creditProfitStructure } from "./constants";

import styles from "./styles.module.scss";

interface NicCobadgeItem {
  value: string;
  key: string;
}

const stringsKeys = [
  "nic-prod-ips",
  "nic-prod-core-name",
  "nic-ccy",
  "product-type",
  "nic-card-subtype-pan-bin",
  "use-test-bin",
  "nic-card-subtype-pan-full-min",
  "nic-card-subtype-pan-full-max",
  "is-co-badged-product",
  "nic-mada-use",
  "co-badged-name",
  creditInterestType.code,
  creditProfitStructure.code,
  "add-prod-card-control",
  "add-prod-qmr",
  "nic-card-subtype-crdiss",
];

export const ProductDetails: FC = () => {
  const nicCobadgeList: NicCobadgeItem[] = [{ value: "MADA", key: "MADA" }];

  const [form] = Form.useForm<FormValues>();
  const [tenant] = useReduxState<Tenant>("tenant", {});

  const { productId } = useParams<{ id: string; productId: string }>();
  const { schemeList: paymentMethodsOptions, schemeMap: paymentMethodsMapper } = useGetPaymentScheme();
  const { isDisabled: isCardControlIntegrationDisabled } = useToggleFeature("CARDCNTRL");
  const { currentProduct, onUpdateProduct } = useProductSettings({
    productId: parseInt(productId ?? "0", 10),
    isFetchEnabled: false,
  });

  const isIslamicCredit = Form.useWatch(creditInterestType.code, form) === "Islamic";
  const isDisabled = useProductStateFormDisabled();

  const { productType, currencyLabel, productCurrency } = useMemo(() => {
    return {
      productType: getFormValueFromProductValues(currentProduct.productValues, "product-type"),
      currencyLabel: getFormValueFromProductValues(currentProduct.productValues, "curr-setup"),
      productCurrency: getFormValueFromProductValues(currentProduct.productValues, "nic-ccy"),
    };
  }, [currentProduct.productValues]);

  const setDisplayNameValue = useCallback(() => {
    const components: string[] = [];
    const values: FormValues = form.getFieldsValue();
    const productIps = paymentMethodsMapper?.get(values["nic-prod-ips"] as string);

    if (productIps) components.push(productIps);

    if (values["is-co-badged-product"] && values["co-badged-name"]) {
      components.push(`- ${values["co-badged-name"] as string}`);
    }

    if (values["nic-prod-core-name"]) {
      components.push(values["nic-prod-core-name"] as string);
    }

    if (productCurrency) {
      components.push(productCurrency as string);
    }

    if (currencyLabel === "mult") {
      components.push("Multicurrency");
    }

    form.setFieldsValue({ displayName: components.join(" ") });
  }, [currencyLabel, form, paymentMethodsMapper, productCurrency]);

  useHydrateForm(
    {
      form,
      entityFields: currentProduct.productValues ?? [],
      keys: {
        strings: stringsKeys,
      },
    },
    [currentProduct.productValues],
  );

  const clearMadaSelect = () => {
    form.setFieldsValue({
      "nic-mada-use": "",
      "co-badged-name": "",
    });

    setDisplayNameValue();
  };

  const onSubmit = (values: FormValues) => {
    values["nic-mada-use"] = !!values["is-co-badged-product"] && values["co-badged-name"] === "MADA";
    void onUpdateProduct(values);
  };

  return (
    <CustomFormWrapper
      form={form}
      pageTitle="Product Details"
      submitHandler={onSubmit}
      onValuesChange={form.validateFields}
      size="md"
      formSize="md"
      submitLabel="Save"
      gap={24}
    >
      <div className={styles["product-details-card-image"]}>
        <div className={styles["product-details-card-image-title"]}>Product image</div>
        <CardView
          cardSize="large"
          cardImage={currentProduct.cardImage}
          prodIps={(getFormValueFromProductValues(currentProduct.productValues, "nic-prod-ips") as string) || "-"}
        />
      </div>

      <PaymentScheme tenant={tenant} schemeList={paymentMethodsOptions} onChange={setDisplayNameValue} />

      <NetworkForm.Switch
        formItemOptions={{
          name: "is-co-badged-product",
        }}
        onChange={clearMadaSelect}
        disabled={true}
        customLabel={
          <>
            <Typography.Text>Co-badged product</Typography.Text>
            <div className="alert-div m-l-16">Coming soon</div>
          </>
        }
      />

      <Form.Item noStyle={true} dependencies={["is-co-badged-product"]}>
        {() => (
          <NetworkForm.Select
            formItemOptions={{
              name: "co-badged-name",
              label: "Co-badged scheme",
              rules: [
                {
                  required: form.getFieldValue("is-co-badged-product") as boolean,
                  message: "Co-badged scheme is required.",
                },
              ],
            }}
            optionList={nicCobadgeList.map(nicCobadge => ({
              label: nicCobadge.value,
              value: nicCobadge.key,
            }))}
            disabled={!form.getFieldValue("is-co-badged-product")}
            onChange={setDisplayNameValue}
          />
        )}
      </Form.Item>

      <NetworkForm.String
        formItemOptions={{
          name: "nic-prod-core-name",
          label: "Product name",
          tooltip:
            "This parameter specifies the name of the card product. No need to repeat Payment schema name in this field. e.g.: Platinum, Gold, Titanium",
          rules: [
            { required: true, message: "Product core name is required." },
            { max: 255, message: "Product core name must have maximum 255 characters." },
            {
              pattern: /^[^\t\r{}]*$/,
              message: "Product Name contains not permitted characters as: “TAB”, `{', '}` ”.",
            },
          ],
        }}
        placeholder="Product name"
        onChange={setDisplayNameValue}
      />

      {getFormValueFromProductValues(currentProduct.productValues, "balance-owner") !== "CMS" && (
        <NetworkForm.DictionarySelect
          formItemOptions={{ name: "nic-ccy", label: "Product currency" }}
          placeholder="Product currency"
          filterOption={filterByDisplayValue}
          onChange={setDisplayNameValue}
          code={PRODUCT_CURRENCY}
          showSearch={true}
        />
      )}

      <NetworkForm.Select
        formItemOptions={{
          name: "product-type",
          label:
            (getFormValueFromProductValues(currentProduct.productValues, "balance-owner") as string) === "CMS"
              ? "Product group"
              : "Product group for reports",
          tooltip:
            (getFormValueFromProductValues(currentProduct.productValues, "balance-owner") as string) === "CMS"
              ? {
                  title:
                    "Product group parameter defines category of product for displaying in reports it could be different from product type.",
                  icon: <QuestionCircleFilled />,
                }
              : undefined,
        }}
        disabled={
          isDisabled ||
          (getFormValueFromProductValues(currentProduct.productValues, "balance-owner") as string) === "CMS"
        }
        optionList={productTypeOptionsWithKey.map(productType => ({
          label: productType.value as string,
          value: productType.value,
        }))}
      />

      {productType === "Credit" && (
        <>
          <NetworkForm.Radio
            formItemOptions={{
              name: creditInterestType.code,
              label: creditInterestType.label,
              initialValue: creditInterestType.default,
            }}
            radioList={creditInterestType.options}
            gap={8}
            disabled={true}
          />

          {isIslamicCredit && (
            <NetworkForm.Radio
              formItemOptions={{
                name: creditProfitStructure.code,
                label: creditProfitStructure.label,
                initialValue: creditProfitStructure.default,
              }}
              radioList={creditProfitStructure.options}
              gap={8}
              disabled={true}
            />
          )}
        </>
      )}

      <NetworkForm.String
        formItemOptions={{
          name: "displayName",
          label: "Product display name",
          initialValue: currentProduct.displayName,
        }}
        placeholder="Product displayed name"
        disabled={true}
      />

      <NetworkForm.String
        formItemOptions={{
          name: "nic-card-subtype-crdiss",
          label: "Base24 financial institution ID",
          tooltip:
            "This field is utilized in interface files transferring data from the Way4 system to the Base24 system. These interface files encompass CAF, PBF, and SEMF files.",
        }}
      />

      {!isCardControlIntegrationDisabled && tenant?.isCardControlSelected && (
        <>
          <div className="text-blue-h3">
            <TooltipInfo
              label="Card Control Setup Parameters"
              tooltipProps={{
                title: "The following switches are used to manage card control enrollment",
              }}
            />
          </div>

          <NetworkForm.Switch
            formItemOptions={{
              name: "add-prod-card-control",
              label: "Add Product to Card Control",
              valuePropName: "checked",
              tooltip: "Enabling This Switch will enroll the product in card control system.",
            }}
            disabled={isDisabled}
          />

          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "nic-ncontrol-flag-def-new",
              label: "Set default card control enrollment flag as enabled for the product",
              initialValue: true,
            }}
            disabled={isDisabled}
          />

          <NetworkForm.Switch
            formItemOptions={{
              valuePropName: "checked",
              name: "nic-ncontrol-flag-editable",
              label: "Card control enrollment flag is editable for contracts",
              initialValue: true,
            }}
            disabled={isDisabled}
          />
        </>
      )}
    </CustomFormWrapper>
  );
};
