import { Axios } from "axios";

import { UserService } from "../services";

export const KeycloakInterceptor = (axios: Axios): void => {
  axios.interceptors.request.use(config => {
    if (UserService.isLoggedIn()) {
      const cb = () => {
        if (config?.headers) {
          config.headers.Authorization = `Bearer ${UserService.getToken()}`;
          config.headers.Timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        }
        return Promise.resolve(config);
      };
      return UserService.updateToken(cb);
    }
    return void UserService.doLogin();
  });
};
