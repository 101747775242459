import { ConfigProvider, Layout } from "antd";
import { AliasToken } from "antd/lib/theme/internal";

import "ni-ui-kit/dist/index.css";
import theme from "../constants/theme";
import colors from "../constants/colors";

import styles from "../styles.module.scss";

const niToken: Partial<AliasToken> = {
  colorPrimary: colors.primaryColor,
  colorBgLayout: colors.layoutBodyBackground,
  colorBgContainer: colors.componentBackground,
  colorTextBase: colors.textColor,
  colorTextHeading: colors.headingColor,
  colorSuccess: colors.successColor,
  colorInfo: colors.infoColor,
  colorWarningText: colors.warningColor,
  colorErrorText: colors.errorColor,
  colorPrimaryBorder: colors.borderColorBase,
  borderRadius: theme.borderRadiusBase,
  borderRadiusSM: theme.borderRadiusSm,
  fontFamily: theme.fontFamily,
  controlHeightSM: theme.heightSm,
  controlHeightLG: theme.heightLg,
  colorIconHover: "fade(@text-color, 80%)",
  padding: theme.paddingBase,
};

const niTabsColors = {
  itemActiveColor: colors.tabsCardActiveColor,
  inkBarColor: colors.tabsInkBarColor,
  itemHoverColor: colors.tabsHoverColor,
  itemSelectedColor: colors.tabsHighlightColor,
};

export default function withAntdTheme<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>) {
  return function WithAntdTheme(props: P): JSX.Element {
    return (
      <ConfigProvider
        theme={{
          token: niToken,
          components: {
            Tabs: niTabsColors,
            Button: {
              colorLink: colors.primaryColor,
            },
            Checkbox: {
              borderRadius: theme.checkboxBorderRadius,
            },
            Space: {
              padding: theme.paddingBase * 2,
            },
          },
        }}
      >
        <Layout className={styles["App"]}>
          <Component {...props} />
        </Layout>
      </ConfigProvider>
    );
  };
}
