import { SelectOption } from "@ni/common/types";

export const MCC_LIST: SelectOption[] = [
  { value: "3420 - ANSA International", key: "3420" },
  { value: "3421 - Allstat Rent-A-Car", key: "3421" },
  { value: "3423 - Avcar Rent-A-Car", key: "3423" },
  { value: "3425 - Automate Rent-A-Car", key: "3425" },
  { value: "3427 - Avon Rent-A-Car", key: "3427" },
  { value: "3428 - Carey Rent-A-Car", key: "3428" },
  { value: "3429 - Insurance Rent-A-Car", key: "3429" },
  { value: "3430 - Major Rent-A-Car", key: "3430" },
  { value: "3431 - Replacement Rent-A-Car", key: "3431" },
  { value: "3432 - Reserve Rent-A-Car", key: "3432" },
  { value: "3433 - Ugly Duckling Rent-A-Car", key: "3433" },
  { value: "3434 - USA Rent-A-Car", key: "3434" },
  { value: "3435 - Value Rent-A-Car", key: "3435" },
  { value: "3436 - Autohansa Rent-A-Car", key: "3436" },
  { value: "3437 - Cite Rent-A-Car", key: "3437" },
  { value: "3438 - Interent Rent-A-Car", key: "3438" },
  { value: "3439 - Milleville Rent-A-Car", key: "3439" },
  { value: "3441 - Advantage Rent-A-Car", key: "3441" },
  { value: "3501 - Holiday Inns", key: "3501" },
  { value: "3502 - Best Western Int'l", key: "3502" },
  { value: "3503 - Sheraton Hotels", key: "3503" },
  { value: "3504 - Hilton Hotels", key: "3504" },
  { value: "3505 - Forte Hotels", key: "3505" },
  { value: "3506 - Golden Tulip", key: "3506" },
  { value: "3507 - Friendship Inns Int`l", key: "3507" },
  { value: "3508 - Quality Int`l", key: "3508" },
  { value: "3509 - Marriott", key: "3509" },
  { value: "3510 - Days Inns Of America", key: "3510" },
  { value: "3511 - Arabella Hotels", key: "3511" },
  { value: "3512 - Intercontinental", key: "3512" },
  { value: "3513 - Westin Hotels", key: "3513" },
  { value: "3514 - Amerisuites", key: "3514" },
  { value: "3515 - Rodeway Inns Int`l", key: "3515" },
  { value: "3516 - La Quinta Motor Inns", key: "3516" },
  { value: "3517 - Americana Htls Corp.", key: "3517" },
  { value: "3518 - Sol Hotels", key: "3518" },
  { value: "3519 - PLM/ETAP Int'l", key: "3519" },
  { value: "3520 - Meridien", key: "3520" },
  { value: "3522 - Tokyo Group", key: "3522" },
  { value: "3523 - Pennisilvania Hotels", key: "3523" },
  { value: "3524 - Welcom Group Hotels", key: "3524" },
  { value: "3525 - Dunfey Hotels", key: "3525" },
  { value: "3526 - Prince Hotels", key: "3526" },
  { value: "3527 - Downtowner-Passport", key: "3527" },
  { value: "3528 - Red Lion Inns", key: "3528" },
  { value: "3529 - CP(Canadian Pacific)", key: "3529" },
  { value: "3530 - Stouffer Corporation", key: "3530" },
  { value: "3533 - Hotel Ibis", key: "3533" },
  { value: "3534 - Southern Pacific", key: "3534" },
  { value: "3535 - Hilton Int'l", key: "3535" },
  { value: "3536 - AMFAC Hotels", key: "3536" },
  { value: "3537 - Ana Hotels", key: "3537" },
  { value: "3538 - Concorde Hotels", key: "3538" },
  { value: "3540 - Iberotel", key: "3540" },
  { value: "3541 - Hotel Okura", key: "3541" },
  { value: "3542 - Royal Hotels", key: "3542" },
  { value: "3543 - Four Seasons", key: "3543" },
  { value: "3544 - Ciga Hotels", key: "3544" },
  { value: "3545 - Shangri-la International", key: "3545" },
  { value: "3548 - Hoteles Melia", key: "3548" },
  { value: "3549 - Auberge Des Gouverneusses", key: "3549" },
  { value: "3550 - Regal 8 Inns", key: "3550" },
  { value: "3552 - Coast Hotel", key: "3552" },
  { value: "3553 - Park Inn by Radisson", key: "3553" },
  { value: "3558 - Jolly Hotels", key: "3558" },
  { value: "3562 - Comfort Hotels Int'l", key: "3562" },
  { value: "3563 - Jouney's End Hotels", key: "3563" },
  { value: "3565 - Relax Inns", key: "3565" },
  { value: "3568 - Ladbroke Hotels", key: "3568" },
  { value: "3570 - Forum Hotels", key: "3570" },
  { value: "3572 - Miyako Hotel/Kintetsu", key: "3572" },
  { value: "3573 - Sandman Hotels", key: "3573" },
  { value: "3574 - Venture Inn", key: "3574" },
  { value: "3575 - Vagabond Hotels", key: "3575" },
  { value: "3577 - Mandarin Int`l", key: "3577" },
  { value: "3579 - Hotel Mercure", key: "3579" },
  { value: "3581 - Delta Hotels", key: "3581" },
  { value: "3583 - Radisson BLU", key: "3583" },
  { value: "3584 - Princess Hotels Int'l", key: "3584" },
  { value: "3585 - Hungar Hotels", key: "3585" },
  { value: "3586 - Sokos Hotels", key: "3586" },
  { value: "3587 - Doral Hotels", key: "3587" },
  { value: "3588 - Helmsley Hotels", key: "3588" },
  { value: "3590 - Fairmont Hotels Corp.", key: "3590" },
  { value: "3591 - Sonesta Int'l Hotels", key: "3591" },
  { value: "3592 - Omni International", key: "3592" },
  { value: "3593 - Cunard Hotels", key: "3593" },
  { value: "3595 - Hospitality Inns", key: "3595" },
  { value: "3598 - Regent Hotels", key: "3598" },
  { value: "3599 - Pannoria Hotel", key: "3599" },
  { value: "3603 - Noah's Hotel (Melbn)", key: "3603" },
  { value: "3612 - Movenpick Hotels", key: "3612" },
  { value: "3615 - Travelodge Motels", key: "3615" },
  { value: "3622 - Merlin Hotel (Perth)", key: "3622" },
  { value: "3623 - Dorint Hotels", key: "3623" },
  { value: "3625 - Hotel Universal", key: "3625" },
  { value: "3629 - Dan Hotels", key: "3629" },
  { value: "3631 - Sleep Inn", key: "3631" },
  { value: "3632 - The Phoenician", key: "3632" },
  { value: "3633 - Rank-hotels", key: "3633" },
  { value: "3634 - Swiss Hotels", key: "3634" },
  { value: "3635 - Reso Hotels", key: "3635" },
  { value: "3636 - Sarova Hotels", key: "3636" },
  { value: "3637 - Ramada Inns", key: "3637" },
  { value: "3638 - Howard Jonhson", key: "3638" },
  { value: "3639 - Mount Charlotte Thistle", key: "3639" },
  { value: "3640 - Hyatt Hotels/Int'l", key: "3640" },
  { value: "3641 - Sofitel Hotels", key: "3641" },
  { value: "3642 - Novotel Sieh (Accor)", key: "3642" },
  { value: "3643 - Steinberger Hotels", key: "3643" },
  { value: "3644 - Econo-Travel Mtr Htl", key: "3644" },
  { value: "3645 - Queens Moat-hotels", key: "3645" },
  { value: "3646 - Swallow Hotels", key: "3646" },
  { value: "3647 - Grupo Hotels Husa SA", key: "3647" },
  { value: "3648 - De Vera Hotels", key: "3648" },
  { value: "3649 - Radisson", key: "3649" },
  { value: "3650 - Red Roof Inns", key: "3650" },
  { value: "3651 - Imperial London Hotels", key: "3651" },
  { value: "3653 - Penta Hotels", key: "3653" },
  { value: "3654 - Loews Hotels", key: "3654" },
  { value: "3655 - Scandic Hotels", key: "3655" },
  { value: "3656 - Sara Hotels", key: "3656" },
  { value: "3657 - Oberoi Hotels", key: "3657" },
  { value: "3658 - New Otani Hotels", key: "3658" },
  { value: "3659 - Taj Hotels Int'l", key: "3659" },
  { value: "3660 - Knights Inn", key: "3660" },
  { value: "3661 - Metropole Hotels", key: "3661" },
  { value: "3663 - Hoteles El Presidente", key: "3663" },
  { value: "3664 - Flag Inns", key: "3664" },
  { value: "3665 - Hampton Inns", key: "3665" },
  { value: "3666 - Stakis Hotels", key: "3666" },
  { value: "3668 - Maritim Hotels", key: "3668" },
  { value: "3670 - Arcade Hotels", key: "3670" },
  { value: "3671 - Arctia Hotels", key: "3671" },
  { value: "3672 - Campanila Hotels", key: "3672" },
  { value: "3673 - Ibusz Hotels", key: "3673" },
  { value: "3674 - Rantasipi Hotels", key: "3674" },
  { value: "3675 - Interhotel Cedok", key: "3675" },
  { value: "3677 - Climat De France Hotels", key: "3677" },
  { value: "3678 - Cumulus Hotels", key: "3678" },
  { value: "3680 - Hoteis Othan", key: "3680" },
  { value: "3681 - Adams Mark Hotels", key: "3681" },
  { value: "3683 - Bradbury Hotels", key: "3683" },
  { value: "3684 - Budget Hosts Inns", key: "3684" },
  { value: "3685 - Budgetel Inns", key: "3685" },
  { value: "3686 - Suisse Hotels", key: "3686" },
  { value: "3687 - Clarion Hotels", key: "3687" },
  { value: "3688 - Compri Hotels", key: "3688" },
  { value: "3689 - Consort Hotels", key: "3689" },
  { value: "3690 - Courtyard Inns", key: "3690" },
  { value: "3691 - Dillin Inn", key: "3691" },
  { value: "3692 - Doubletree Hotels", key: "3692" },
  { value: "3693 - Drury Inn", key: "3693" },
  { value: "3694 - Economy Inns Of America", key: "3694" },
  { value: "3695 - Embassy Suites", key: "3695" },
  { value: "3696 - Excel Inn", key: "3696" },
  { value: "3697 - Fairfield Hotels", key: "3697" },
  { value: "3698 - Harley Hotels", key: "3698" },
  { value: "3699 - Midway Motor Lodge", key: "3699" },
  { value: "3700 - Motel 6", key: "3700" },
  { value: "3702 - The Registry Hotels", key: "3702" },
  { value: "3703 - Residence Inn", key: "3703" },
  { value: "3704 - Royce Hotels", key: "3704" },
  { value: "3705 - Sandman Inn", key: "3705" },
  { value: "3706 - Shilo Inn", key: "3706" },
  { value: "3707 - Shoney's Inn", key: "3707" },
  { value: "3709 - Super 8 Motels", key: "3709" },
  { value: "3710 - The Ritz Carlton", key: "3710" },
  { value: "3711 - Flag Inns (australia)", key: "3711" },
  { value: "3713 - Quality Pacific Hotel", key: "3713" },
  { value: "3714 - Four Seasons(australia) Hot", key: "3714" },
  { value: "3715 - Fairfield Inn", key: "3715" },
  { value: "3716 - Carlton Hotels", key: "3716" },
  { value: "3717 - City Lodge Hotels", key: "3717" },
  { value: "3718 - Karos Hotels", key: "3718" },
  { value: "3719 - Protea Hotels", key: "3719" },
  { value: "3720 - Southern Sun Hotels", key: "3720" },
  { value: "3721 - Conrad Hotels", key: "3721" },
  { value: "3722 - Wyndham", key: "3722" },
  { value: "3723 - Rica Hotels", key: "3723" },
  { value: "3724 - Inter Nor Hotels", key: "3724" },
  { value: "3725 - Seapines Plantation", key: "3725" },
  { value: "3726 - Rio Suites", key: "3726" },
  { value: "3727 - Broadmoor Hotel", key: "3727" },
  { value: "3728 - Bally's Hotel And Casino", key: "3728" },
  { value: "3729 - John Ascuaga's Nugget", key: "3729" },
  { value: "3730 - MGM Grand Hotel", key: "3730" },
  { value: "3731 - Harrah's Hotels And Casinos", key: "3731" },
  { value: "3732 - Opryland Hotel", key: "3732" },
  { value: "3733 - Boca Raton Resort", key: "3733" },
  { value: "3734 - Harvey Bristol Hotels", key: "3734" },
  { value: "3735 - Masters Economy Inns", key: "3735" },
  { value: "3736 - Colorado Belle Edgewater Re", key: "3736" },
  { value: "3737 - Riviera Hotel And Casino", key: "3737" },
  { value: "3738 - Tropicana Resort And Casino", key: "3738" },
  { value: "3739 - Woodside Hotels And Resorts", key: "3739" },
  { value: "3740 - Marriot/Town Place Suites", key: "3740" },
  { value: "3741 - Millennium Hotels", key: "3741" },
  { value: "3742 - Club Med", key: "3742" },
  { value: "3743 - Biltmore Hotel And Suites", key: "3743" },
  { value: "3744 - Carefree Resorts", key: "3744" },
  { value: "3745 - St. Regis Hotel", key: "3745" },
  { value: "3746 - Eliot Hotels", key: "3746" },
  { value: "3747 - Club Corporate/Club Resorts", key: "3747" },
  { value: "3748 - Wellesley Inss", key: "3748" },
  { value: "3749 - The Beverly Hills Hotel", key: "3749" },
  { value: "3750 - Crowne Plaza Hotels", key: "3750" },
  { value: "3751 - Homewood Suites", key: "3751" },
  { value: "3752 - Peabody Hotels", key: "3752" },
  { value: "3753 - Greenbriar Resorts", key: "3753" },
  { value: "3754 - Amelia Island Plantation", key: "3754" },
  { value: "3755 - The Homestead", key: "3755" },
  { value: "3756 - South Seas Resorts", key: "3756" },
  { value: "3757 - Canyon Ranch", key: "3757" },
  { value: "3758 - Kahala Mandarin Oriental Ho", key: "3758" },
  { value: "3759 - The Orchid At Mauna Lani", key: "3759" },
  { value: "3760 - Halekulani/Waikiki Parc", key: "3760" },
  { value: "4011 - Railroads - Freight", key: "4011" },
  { value: "4111 - Local/Suburban Commuter Pas", key: "4111" },
  { value: "4112 - Passenger RailwaysX", key: "4112" },
  { value: "4119 - Ambulance Service", key: "4119" },
  { value: "4121 - Taxicabs/limousines", key: "4121" },
  { value: "4131 - Bus Lines", key: "4131" },
  { value: "4214 - Motor Freight Carriers", key: "4214" },
  { value: "4215 - Courier Services-air Or Gro", key: "4215" },
  { value: "4225 - Public Warehousing", key: "4225" },
  { value: "4411 - Cruise Lines", key: "4411" },
  { value: "4457 - Boat Rentals And Lease", key: "4457" },
  { value: "4468 - Marinas,marine Service/supp", key: "4468" },
  { value: "4582 - Airports,flying Fields", key: "4582" },
  { value: "4722 - Travel Agencies And Tour Op", key: "4722" },
  { value: "4784 - Tolls,road And Bridge Fees", key: "4784" },
  { value: "4789 - Transportation Services", key: "4789" },
  { value: "4812 - Telephone Service/Equip....", key: "4812" },
  { value: "4813 - Key-Enter Telecom Merchant", key: "4813" },
  { value: "4814 - Telecommunication Service", key: "4814" },
  { value: "4815 - Monthly Summary Telephone C", key: "4815" },
  { value: "4816 - Computer Network/Informatio", key: "4816" },
  { value: "4821 - Telegraph Servoces Wire Tra", key: "4821" },
  { value: "8071 - Medical & Dental Laboratori", key: "8071" },
  { value: "4899 - Cable, Satellite, and Other Pay Television and Radio Services", key: "4899" },
  { value: "4900 - Utilities-electric,gas,wate", key: "4900" },
  { value: "5013 - Motor Vehicle Parts/Supplie", key: "5013" },
  { value: "5021 - Office And Commercial Furni", key: "5021" },
  { value: "5039 - Constuction Materials N-Els", key: "5039" },
  { value: "5044 - Office/Photogr/Cpy/Microfil", key: "5044" },
  { value: "5045 - Computers/Peripheral/Softwr", key: "5045" },
  { value: "5046 - Commercial Equipment N-Else", key: "5046" },
  { value: "5047 - Lab/Medic/Dental/Opth/Equip", key: "5047" },
  { value: "5051 - Metal Service Centres/Offic", key: "5051" },
  { value: "5065 - Electric Parts/Equipment", key: "5065" },
  { value: "5072 - Hardware Equipment/Supplies", key: "5072" },
  { value: "5074 - Plumbing/Heating Equipment", key: "5074" },
  { value: "5085 - Industrial Supplies N-Else", key: "5085" },
  { value: "5094 - Precious Stones/Metals/Jewe", key: "5094" },
  { value: "5099 - Durable Goods N-elsewhere", key: "5099" },
  { value: "5111 - Stationery/Off-Supp/Printin", key: "5111" },
  { value: "5122 - Drugs/Drug Propriat/Sundrie", key: "5122" },
  { value: "5131 - Piece Goods/Nations Dry Goo", key: "5131" },
  { value: "5137 - Men/Women/Child Uniforms/Co", key: "5137" },
  { value: "5139 - Commercial Footwear", key: "5139" },
  { value: "5169 - Chemicals/Allied Prod N-Els", key: "5169" },
  { value: "5172 - Petroleum/Petrol Products", key: "5172" },
  { value: "5192 - Books/Periodicals/Newspaper", key: "5192" },
  { value: "5193 - Florist/Nursery Stock/Flowe", key: "5193" },
  { value: "5198 - Paints/Varnishes Supplies", key: "5198" },
  { value: "5199 - Nondurable Goods N-Else", key: "5199" },
  { value: "5200 - Home Supply,Warehouse Store", key: "5200" },
  { value: "5211 - Lumber And Building Materia", key: "5211" },
  { value: "5231 - Glass,paint,wallpaper Store", key: "5231" },
  { value: "5251 - Hardware Stores", key: "5251" },
  { value: "5261 - Nurseries,lawn & Garden", key: "5261" },
  { value: "5271 - Mobile Home Dealers", key: "5271" },
  { value: "5300 - Wholesale Clubs", key: "5300" },
  { value: "5309 - Duty Free Stores", key: "5309" },
  { value: "5331 - Variety Stores", key: "5331" },
  { value: "5399 - Miscellaneous General Merch", key: "5399" },
  { value: "5411 - Grocery Stores,supermarkets", key: "5411" },
  { value: "5422 - Freezer And Locker Meat Pro", key: "5422" },
  { value: "5441 - Candy,nut,confectionary Sto", key: "5441" },
  { value: "5451 - Dairy Products Stores", key: "5451" },
  { value: "5462 - Bakeries", key: "5462" },
  { value: "5499 - Miscellaneous Food Stores", key: "5499" },
  { value: "5511 - Automobile & Truck Dealers", key: "5511" },
  { value: "5521 - Automobile & Truck Dealers", key: "5521" },
  { value: "5531 - Auto & Home Supply Stores", key: "5531" },
  { value: "5532 - Automotive Tire Stores", key: "5532" },
  { value: "5533 - Automotive Parts,acces. Sto", key: "5533" },
  { value: "5541 - Service Stations", key: "5541" },
  { value: "5542 - Automated Fuel Dispenser", key: "5542" },
  { value: "5551 - Boat Dealers", key: "5551" },
  { value: "5561 - Recreational And Utility", key: "5561" },
  { value: "5571 - Motorcycle Shops And Dealer", key: "5571" },
  { value: "5592 - Motor Home Dealers", key: "5592" },
  { value: "5598 - Snowmobile Dealers", key: "5598" },
  { value: "5599 - Miscellaneous Automotive De", key: "5599" },
  { value: "5611 - Men's And Boys' Clothing", key: "5611" },
  { value: "5621 - Women's Ready-to-wear Store", key: "5621" },
  { value: "5631 - Women's Accessory And Speci", key: "5631" },
  { value: "5641 - Children's And Infant's Wea", key: "5641" },
  { value: "5651 - Family Clothing Stores", key: "5651" },
  { value: "5655 - Sports Apparel,riding App.s", key: "5655" },
  { value: "5681 - Furriers And Fur Shops", key: "5681" },
  { value: "5691 - Men's And Ladies's Clothing", key: "5691" },
  { value: "5697 - Tailors,seamstress,mending", key: "5697" },
  { value: "5698 - Wig & Toupee Shops", key: "5698" },
  { value: "5699 - Miscellaneous Apparel & Acc", key: "5699" },
  { value: "5712 - Furniture,home Furnishings", key: "5712" },
  { value: "5713 - Floor Covering,rugs,and Car", key: "5713" },
  { value: "5714 - Drapery & Upholstery Stores", key: "5714" },
  { value: "5718 - Fireplace,fireplace Screens", key: "5718" },
  { value: "5719 - Miscellaneous House Furnish", key: "5719" },
  { value: "5722 - Household Appliance Stores", key: "5722" },
  { value: "5732 - Electronic Sales", key: "5732" },
  { value: "5733 - Music Stores-musical Instru", key: "5733" },
  { value: "5734 - Computer Software Stores", key: "5734" },
  { value: "5735 - Record Shops", key: "5735" },
  { value: "5811 - Caterers", key: "5811" },
  { value: "5812 - Eating Places,Restaurants", key: "5812" },
  { value: "5813 - Drinking Places - Bars,Tave", key: "5813" },
  { value: "5814 - Fast Food Res. (Quick P S P", key: "5814" },
  { value: "5912 - Drug Stores,pharmacies", key: "5912" },
  { value: "5921 - Package Stores - Beer, Liqu", key: "5921" },
  { value: "5931 - Used Merchandise Stores", key: "5931" },
  { value: "5932 - Antique Shops-Sales, Repair", key: "5932" },
  { value: "5933 - Pawn Shops", key: "5933" },
  { value: "5935 - Wrecking & Salvage Yards", key: "5935" },
  { value: "5937 - Antique Reproduction Stores", key: "5937" },
  { value: "5940 - Bicycle Shops-sales & Servi", key: "5940" },
  { value: "5941 - Sporting Goods Stores", key: "5941" },
  { value: "5942 - Book Stores", key: "5942" },
  { value: "5943 - Stationery,office,and Schoo", key: "5943" },
  { value: "5944 - Jewelry Stores - Watches", key: "5944" },
  { value: "5945 - Hobby,toy,and Game Shops", key: "5945" },
  { value: "5946 - Camera And Photographic Sup", key: "5946" },
  { value: "5947 - Gift,card,novelty And Souve", key: "5947" },
  { value: "5948 - Luggage And Leather Goods S", key: "5948" },
  { value: "3390 - Dollar Rent A Car", key: "3390" },
  { value: "3652 - embassy hotels", key: "3652" },
  { value: "4511 - Airlines, Air Carriers", key: "4511" },
  { value: "4829 - Money Transfer", key: "4829" },
  { value: "5310 - Discount stores", key: "5310" },
  { value: "5311 - Department stores", key: "5311" },
  { value: "5661 - Shoe stores", key: "5661" },
  { value: "5992 - Florists", key: "5992" },
  { value: "5999 - Miscellaneous & specialty r", key: "5999" },
  { value: "6010 - POS Cash", key: "6010" },
  { value: "6011 - ATM", key: "6011" },
  { value: "6051 - Foreign Currency,Money,TC's", key: "6051" },
  { value: "7995 - Casino", key: "7995" },
  { value: "0742 - Veterinary Services", key: "0742" },
  { value: "0763 - Agricultural Cooperatives", key: "0763" },
  { value: "0780 - Landscape And Horticultural", key: "0780" },
  { value: "1520 - Gen Cont Residential Buildi", key: "1520" },
  { value: "1711 - Heating,Plumbing,Air Condit", key: "1711" },
  { value: "1731 - Electrical Contractors", key: "1731" },
  { value: "1740 - Masonry,Stonework,Tile Sett", key: "1740" },
  { value: "1750 - Carpentry Contractors", key: "1750" },
  { value: "1761 - Roofing And Siding,Sheet Me", key: "1761" },
  { value: "1771 - Concrete Work Contractors", key: "1771" },
  { value: "1799 - Special Trade Contractors", key: "1799" },
  { value: "2741 - Misc-Publishing/Printing", key: "2741" },
  { value: "2791 - Typesetting/Plate Related", key: "2791" },
  { value: "2842 - Special Clean/Polish/Sanit", key: "2842" },
  { value: "3000 - United Airlines", key: "3000" },
  { value: "3001 - American Airlines", key: "3001" },
  { value: "3002 - Pan American", key: "3002" },
  { value: "3004 - DRAGONAIR", key: "3004" },
  { value: "3005 - British Airways", key: "3005" },
  { value: "3006 - Japan Air Lines", key: "3006" },
  { value: "3007 - Air France", key: "3007" },
  { value: "3008 - Lufthansa", key: "3008" },
  { value: "3009 - Air Canada", key: "3009" },
  { value: "3010 - KLM", key: "3010" },
  { value: "3011 - Aeroflot", key: "3011" },
  { value: "3012 - Qantas", key: "3012" },
  { value: "3013 - Alitalia", key: "3013" },
  { value: "3014 - Saudi Arabian Airlines", key: "3014" },
  { value: "3015 - SWISS International Airlines", key: "3015" },
  { value: "3016 - SAS", key: "3016" },
  { value: "3017 - South African Airways", key: "3017" },
  { value: "3018 - Varig (Brazil)", key: "3018" },
  { value: "3020 - Air-India", key: "3020" },
  { value: "3021 - Air Algerie Airalgerei", key: "3021" },
  { value: "3022 - PAL AIR", key: "3022" },
  { value: "3023 - Mexicana", key: "3023" },
  { value: "3024 - Pakistan Int'l", key: "3024" },
  { value: "3025 - Air New Zealand", key: "3025" },
  { value: "3026 - Emirates Airlines", key: "3026" },
  { value: "3027 - UTA/InterAir", key: "3027" },
  { value: "3028 - Air Malta", key: "3028" },
  { value: "3029 - Sabena", key: "3029" },
  { value: "3030 - Aerolineas Argentinas", key: "3030" },
  { value: "3031 - Olympic Airways", key: "3031" },
  { value: "3032 - El Al", key: "3032" },
  { value: "3033 - Ansett Airlines", key: "3033" },
  { value: "3034 - ETIHADAIR", key: "3034" },
  { value: "3035 - TAP (Portugal)", key: "3035" },
  { value: "3036 - VASP (Brazil)", key: "3036" },
  { value: "3037 - EgyptAir", key: "3037" },
  { value: "3038 - Kuwait Airways", key: "3038" },
  { value: "3039 - Avianca", key: "3039" },
  { value: "3040 - Gulf Air (Bahrain)", key: "3040" },
  { value: "3041 - Balkan - Bulgarian Airlines", key: "3041" },
  { value: "3042 - Finnair", key: "3042" },
  { value: "3043 - Aer Lingus", key: "3043" },
  { value: "3044 - Air Lanka", key: "3044" },
  { value: "3045 - Nigeria Airways", key: "3045" },
  { value: "3046 - Cruzeiro Do Sul (Bra.)", key: "3046" },
  { value: "3048 - Royal Air Maroc", key: "3048" },
  { value: "3049 - Tunis Air", key: "3049" },
  { value: "3050 - Icelandair", key: "3050" },
  { value: "3051 - Austrian Airlines", key: "3051" },
  { value: "3052 - Lan Airlines Lan Air", key: "3052" },
  { value: "3053 - AVIACO (Spain)", key: "3053" },
  { value: "3054 - Ladeco (Chile)", key: "3054" },
  { value: "3055 - LAB (Bolivia)", key: "3055" },
  { value: "3056 - JET AIR", key: "3056" },
  { value: "3057 - VIRGIN AMERICA", key: "3057" },
  { value: "3058 - Delta", key: "3058" },
  { value: "3060 - NWA Air", key: "3060" },
  { value: "3061 - Continental", key: "3061" },
  { value: "3063 - U.S. Airwys", key: "3063" },
  { value: "3064 - Adria Airways", key: "3064" },
  { value: "3065 - Air Inter", key: "3065" },
  { value: "3066 - Southwest", key: "3066" },
  { value: "3067 - Vanguard Airlines", key: "3067" },
  { value: "3071 - Air British Columbia", key: "3071" },
  { value: "3075 - Singapore Airlines", key: "3075" },
  { value: "3076 - Aeromexico", key: "3076" },
  { value: "3077 - Thai Airways", key: "3077" },
  { value: "3078 - China Airlines", key: "3078" },
  { value: "3082 - Korean Airlines", key: "3082" },
  { value: "3083 - Airafrique", key: "3083" },
  { value: "3084 - Eva Air", key: "3084" },
  { value: "3085 - Midwest Express Airlines", key: "3085" },
  { value: "3086 - Carnivalair", key: "3086" },
  { value: "3087 - Metro Airlines", key: "3087" },
  { value: "3088 - Croatia Air", key: "3088" },
  { value: "3089 - Transaero", key: "3089" },
  { value: "3090 - Qatar Airways Company W.L.L", key: "3090" },
  { value: "3092 - Midway Airlines", key: "3092" },
  { value: "3094 - Zambia Airways", key: "3094" },
  { value: "3095 - Wardair (canada)", key: "3095" },
  { value: "3096 - Air Zimbabwe", key: "3096" },
  { value: "3097 - Spanair", key: "3097" },
  { value: "3098 - Asiana Airlines", key: "3098" },
  { value: "3099 - Cathay Pacific", key: "3099" },
  { value: "3100 - Malaysian Air Sys.", key: "3100" },
  { value: "3102 - Iberia", key: "3102" },
  { value: "3103 - Garuda (Indonesia)", key: "3103" },
  { value: "3106 - Braathens S.A.F.E(Nor)", key: "3106" },
  { value: "3110 - Wings Airways", key: "3110" },
  { value: "3111 - British Midland", key: "3111" },
  { value: "3112 - Windward Island", key: "3112" },
  { value: "3117 - Viasa", key: "3117" },
  { value: "3118 - Valley Airlines", key: "3118" },
  { value: "3125 - Tan Sahsa", key: "3125" },
  { value: "3126 - Talair", key: "3126" },
  { value: "3127 - Taca Int`l", key: "3127" },
  { value: "3129 - Surinam Airways", key: "3129" },
  { value: "3130 - Sun World Int`l", key: "3130" },
  { value: "3133 - Sunbelt Airlines", key: "3133" },
  { value: "3135 - Sudan Airways", key: "3135" },
  { value: "3137 - Singleton", key: "3137" },
  { value: "3138 - Simmons Airlines", key: "3138" },
  { value: "3143 - Scenic Airlines", key: "3143" },
  { value: "3144 - Virgin Atlantic   Viratl", key: "3144" },
  { value: "3145 - San Juan", key: "3145" },
  { value: "3146 - Luxair", key: "3146" },
  { value: "3151 - Air Zaire  Airzaire", key: "3151" },
  { value: "3154 - Princeville", key: "3154" },
  { value: "3159 - PBA", key: "3159" },
  { value: "3161 - All Nippon Airways   Anaair", key: "3161" },
  { value: "3164 - Norontair", key: "3164" },
  { value: "3165 - New York Helicopter", key: "3165" },
  { value: "3170 - Mount Cook", key: "3170" },
  { value: "3171 - Canadian Airlines Canadian", key: "3171" },
  { value: "3172 - Nation Air   Nationai", key: "3172" },
  { value: "3175 - Middle East Air", key: "3175" },
  { value: "3176 - Metroflight Airlines", key: "3176" },
  { value: "3178 - Mesa Air", key: "3178" },
  { value: "3181 - Malev", key: "3181" },
  { value: "3182 - LOT (Poland)", key: "3182" },
  { value: "3184 - LIAT", key: "3184" },
  { value: "3185 - LAV (Venezuela)", key: "3185" },
  { value: "3186 - LAP (Paraguay)", key: "3186" },
  { value: "3187 - LACSA", key: "3187" },
  { value: "3190 - Jugoslav Air", key: "3190" },
  { value: "3191 - Island Airlines", key: "3191" },
  { value: "3193 - Indian Airlines", key: "3193" },
  { value: "3196 - Hawaiian Air", key: "3196" },
  { value: "3197 - Havasu Airlines", key: "3197" },
  { value: "3200 - Guyana Airways", key: "3200" },
  { value: "3203 - Golden Pacific Air", key: "3203" },
  { value: "3204 - Freedom Airlines", key: "3204" },
  { value: "3212 - Dominicana", key: "3212" },
  { value: "3215 - Dan Air Services", key: "3215" },
  { value: "3216 - Cumberland Airlines", key: "3216" },
  { value: "3217 - CSA", key: "3217" },
  { value: "3218 - Crown Air", key: "3218" },
  { value: "3219 - Copa", key: "3219" },
  { value: "3220 - Compania Faucett", key: "3220" },
  { value: "3221 - Transportes Aereos Militare", key: "3221" },
  { value: "3222 - Command Airways", key: "3222" },
  { value: "3223 - Comair", key: "3223" },
  { value: "3228 - Cayman Airways", key: "3228" },
  { value: "3229 - Saeta", key: "3229" },
  { value: "3231 - Sahsa", key: "3231" },
  { value: "3233 - Capitol Air", key: "3233" },
  { value: "3234 - Carribean Airlines", key: "3234" },
  { value: "3235 - Brockway Air", key: "3235" },
  { value: "3238 - Bemidji Aviation", key: "3238" },
  { value: "3239 - Bar Harbor Airlines", key: "3239" },
  { value: "3240 - Bahamasair", key: "3240" },
  { value: "3241 - Aviateca (Guatemala)", key: "3241" },
  { value: "3242 - Avensa", key: "3242" },
  { value: "3243 - Austrian Air Service", key: "3243" },
  { value: "3251 - Aloha Airlines", key: "3251" },
  { value: "3252 - ALM Antilean Airlines", key: "3252" },
  { value: "3254 - Trump Airline  Trumpair", key: "3254" },
  { value: "3256 - Alaska Airlines", key: "3256" },
  { value: "3259 - American Trans Air", key: "3259" },
  { value: "3261 - Air China", key: "3261" },
  { value: "3262 - Reno Air, Inc.", key: "3262" },
  { value: "3263 - Aero Servicio Carabobo", key: "3263" },
  { value: "3266 - Air Seychelles", key: "3266" },
  { value: "3267 - Air Panama", key: "3267" },
  { value: "3280 - Air Jamaica", key: "3280" },
  { value: "3282 - Air Djibouti", key: "3282" },
  { value: "3284 - Aero Virgin Islands", key: "3284" },
  { value: "3285 - AeroPeru", key: "3285" },
  { value: "3286 - Aero Nicaraguensis", key: "3286" },
  { value: "3287 - Aero Coach Aviation", key: "3287" },
  { value: "3292 - Cyprus Airways", key: "3292" },
  { value: "3293 - Equatoriana", key: "3293" },
  { value: "3294 - Ethiopian Airlines", key: "3294" },
  { value: "3295 - Kenya Airways", key: "3295" },
  { value: "3297 - Tarom Romanian Air Transpor", key: "3297" },
  { value: "3298 - Air Mauritus   Airmauri", key: "3298" },
  { value: "3299 - Wideroe's Flyveselskap", key: "3299" },
  { value: "3351 - Affiliated Auto Rental", key: "3351" },
  { value: "3352 - American International", key: "3352" },
  { value: "3353 - Brooks Rent A Car", key: "3353" },
  { value: "3354 - Action Auto Rental", key: "3354" },
  { value: "3357 - Hertz Corporation", key: "3357" },
  { value: "3359 - Payless Car Rental", key: "3359" },
  { value: "3360 - Snappy Car Rental", key: "3360" },
  { value: "3361 - Airways Rent-A-Car", key: "3361" },
  { value: "3362 - Altra Auto Rental", key: "3362" },
  { value: "3364 - Agency Rent-A-Car", key: "3364" },
  { value: "3366 - Budget Rent-A-Car", key: "3366" },
  { value: "3368 - Holiday Rent-A-Car", key: "3368" },
  { value: "3370 - Rent A Wreck", key: "3370" },
  { value: "3376 - Ajax Rent-A-Car", key: "3376" },
  { value: "3381 - Europ Car", key: "3381" },
  { value: "3385 - Tropical Rent-A-Car", key: "3385" },
  { value: "3386 - Showcase Rental Cars", key: "3386" },
  { value: "3387 - Alama Rent-A-Car", key: "3387" },
  { value: "3389 - Avis Rent-A-Car", key: "3389" },
  { value: "3391 - Europe By Car", key: "3391" },
  { value: "3393 - National Car Rental", key: "3393" },
  { value: "3394 - Kemwell Group Rent-A-Car", key: "3394" },
  { value: "3395 - Thrifty Rent-A-Car", key: "3395" },
  { value: "3396 - Tilden Rent-A-Car", key: "3396" },
  { value: "3398 - Econo Car Rent-A-Car", key: "3398" },
  { value: "3400 - Auto Host Car Rental", key: "3400" },
  { value: "3405 - Enterprise Rent-A-Car", key: "3405" },
  { value: "3409 - General Rent-A-Car", key: "3409" },
  { value: "3412 - A1 Rent-A-Car", key: "3412" },
  { value: "3414 - Godfrey National Rent-A-Car", key: "3414" },
  { value: "3547 - BREAKERS RESORT", key: "3547" },
  { value: "7994 - Video Games Arcades/establi", key: "7994" },
  { value: "7996 - Amusement Parks,circuses", key: "7996" },
  { value: "7997 - Membership Clubs", key: "7997" },
  { value: "7998 - Aquariums,seaquariums", key: "7998" },
  { value: "7999 - Recreation Services (Not El", key: "7999" },
  { value: "8011 - Doctors (Not Elsewhere Clas", key: "8011" },
  { value: "8021 - Dentists,orthodontists", key: "8021" },
  { value: "8031 - Osteopathic Physicians", key: "8031" },
  { value: "8041 - Chiropractors", key: "8041" },
  { value: "8042 - Optometrists,opthamalogists", key: "8042" },
  { value: "8043 - Opticians, Optical Goods, E", key: "8043" },
  { value: "8049 - Chiropodists,podiatrists", key: "8049" },
  { value: "8050 - Nursing And Personal Care F", key: "8050" },
  { value: "8062 - Hospitals", key: "8062" },
  { value: "3605 - JURYS DOYLE HOTEL", key: "3605" },
  { value: "3606 - JEFFERSON HOTEL", key: "3606" },
  { value: "3610 - GAYLORD TEXAN", key: "3610" },
  { value: "3611 - C MON INN", key: "3611" },
  { value: "3616 - HERMITSGE HOTEL", key: "3616" },
  { value: "3803 - THE WIGWAM GOLF RESORT and SPA", key: "3803" },
  { value: "3804 - THE DIPLOMAT COUNTRY CLUB and SPA", key: "3804" },
  { value: "3805 - THE ATLANTIC", key: "3805" },
  { value: "3806 - PRINCEVILLE RESORT", key: "3806" },
  { value: "3810 - LA COSTA RESORT", key: "3810" },
  { value: "8099 - Medical Services", key: "8099" },
  { value: "8111 - Legal Services,attorneys", key: "8111" },
  { value: "8211 - Elementary & Secondary Scho", key: "8211" },
  { value: "8220 - Colleges,Universities", key: "8220" },
  { value: "8241 - Correspondence Schools", key: "8241" },
  { value: "8244 - Business & Secretarial Scho", key: "8244" },
  { value: "8249 - Vocational & Trade Schools", key: "8249" },
  { value: "8299 - Schools & Educational Servi", key: "8299" },
  { value: "8351 - Child Day Care Services", key: "8351" },
  { value: "8398 - Charitable Contributions", key: "8398" },
  { value: "8641 - Civic,social & Fraternal As", key: "8641" },
  { value: "8651 - Political Organizations", key: "8651" },
  { value: "8661 - Religious Organizations", key: "8661" },
  { value: "8675 - Automobile Associations", key: "8675" },
  { value: "8699 - Membership Organizations", key: "8699" },
  { value: "8734 - Testing Labs Non-medical", key: "8734" },
  { value: "8911 - Engineering,architectural", key: "8911" },
  { value: "8931 - Accounting,auditing Service", key: "8931" },
  { value: "8999 - Services,not Elsewhere Clas", key: "8999" },
  { value: "9211 - Court Costs", key: "9211" },
  { value: "9222 - Fines", key: "9222" },
  { value: "9223 - Bail & Bond Payments", key: "9223" },
  { value: "9311 - TAX PAYMENTS", key: "9311" },
  { value: "9399 - Government Services", key: "9399" },
  { value: "9401 - I-Purchasing Pilot", key: "9401" },
  { value: "9402 - Postal Services-Government", key: "9402" },
  { value: "9405 - Intra Government Purchases", key: "9405" },
  { value: "3115 - Tower Air", key: "3115" },
  { value: "3132 - Frontier Airlines", key: "3132" },
  { value: "3136 - Qatar Airways Company W.L.L", key: "3136" },
  { value: "3148 - Air Littoral, S.A.(LITTORAL", key: "3148" },
  { value: "3156 - GO FLY Ltd.", key: "3156" },
  { value: "3206 - China Eastern Airlines", key: "3206" },
  { value: "3374 - Accent Rent-A-Car", key: "3374" },
  { value: "3380 - Triangle Rent-A-Car", key: "3380" },
  { value: "3539 - Summerfield Suites Hotel", key: "3539" },
  { value: "3546 - HOTEL SIERRA", key: "3546" },
  { value: "3551 - Mirage Hotel And Casino", key: "3551" },
  { value: "3555 - Treasure Island Hotel Casin", key: "3555" },
  { value: "3561 - Golden Nugget", key: "3561" },
  { value: "3564 - Sam's Town Hotel And Casino", key: "3564" },
  { value: "3582 - California Hotel And Casino", key: "3582" },
  { value: "3620 - Binion's Horseshoe Club", key: "3620" },
  { value: "3624 - Lady Luck Hotel And Casino", key: "3624" },
  { value: "3628 - Excalibur Hotel And Casino", key: "3628" },
  { value: "3662 - Circus Circus Hotel Casino", key: "3662" },
  { value: "3667 - Luxor Hotel And Casino", key: "3667" },
  { value: "3669 - Eldorado Hotel And Casino", key: "3669" },
  { value: "3676 - Monte Carlo Hotel And Casin", key: "3676" },
  { value: "3679 - Silver Legacy Hotel Casino", key: "3679" },
  { value: "3682 - Sahara Hotel And Casino", key: "3682" },
  { value: "3701 - La Mansion Del Rio", key: "3701" },
  { value: "3708 - Virgin River Hotel Casino", key: "3708" },
  { value: "3712 - Buffalo Bill's Hotel Casino", key: "3712" },
  { value: "3761 - Primadonna Hotel And Casino", key: "3761" },
  { value: "3762 - Whiskey Pete's Hotel Casino", key: "3762" },
  { value: "3763 - Chateau Elan Winery Resort", key: "3763" },
  { value: "3764 - Beau Rivage Hotel And Casin", key: "3764" },
  { value: "3765 - Bellagio", key: "3765" },
  { value: "3766 - Fremont Hotel And Casino", key: "3766" },
  { value: "3767 - Main Street Hotel And Casin", key: "3767" },
  { value: "3768 - Silver Star Hotel And Casin", key: "3768" },
  { value: "3769 - Stratosphere Hotel Casino", key: "3769" },
  { value: "3770 - SpringHill Suites", key: "3770" },
  { value: "3771 - Caesars Hotel And Casino", key: "3771" },
  { value: "3772 - Nemacolin Woodlands", key: "3772" },
  { value: "3773 - Venetion Resort Hotel Casin", key: "3773" },
  { value: "3774 - New York-New York Hotel Cas", key: "3774" },
  { value: "3775 - Ocean Dunes Resort And Vill", key: "3775" },
  { value: "3776 - Nevele Grande Resort And Co", key: "3776" },
  { value: "3777 - Mandalay Bay Resort", key: "3777" },
  { value: "3778 - Four Points Hotels", key: "3778" },
  { value: "3779 - W Hotels", key: "3779" },
  { value: "3780 - Disneyland Hotels", key: "3780" },
  { value: "3781 - Patricia Grand Resort Hotel", key: "3781" },
  { value: "3782 - Rosen Hotels & Resorts", key: "3782" },
  { value: "3783 - Town And Country Resort", key: "3783" },
  { value: "3785 - Outrigger Hotels And Resort", key: "3785" },
  { value: "6531 - Payment Service Provider - ", key: "6531" },
  { value: "6532 - Payment Transaction - Membe", key: "6532" },
  { value: "6533 - Payment Service Provider -", key: "6533" },
  { value: "3180 - WESTJET Airlines", key: "3180" },
  { value: "3792 - Claridge Casino Hotels", key: "3792" },
  { value: "3793 - The Flamingo Hotels", key: "3793" },
  { value: "3794 - Grand Casino Hotels", key: "3794" },
  { value: "3795 - Paris Las Vegas Hotel", key: "3795" },
  { value: "3796 - PEPPERMILL Hotel Casino", key: "3796" },
  { value: "3388 - Merchants Rent-A-Car", key: "3388" },
  { value: "3531 - KAUAI COCONUT BEACH RESORT", key: "3531" },
  { value: "3532 - ROYAL KONA RESORT", key: "3532" },
  { value: "3554 - PINEHURST RESORT", key: "3554" },
  { value: "3556 - BARTON CREEK RESORT", key: "3556" },
  { value: "3798 - EMBASSY VACATION RESORT", key: "3798" },
  { value: "3567 - SOHO GRAND HOTEL", key: "3567" },
  { value: "3569 - TRIBECA GRAND HOTEL", key: "3569" },
  { value: "3557 - MANHATTAN EAST SUITE HOTEL", key: "3557" },
  { value: "3566 - GARDEN PLACE HOTEL", key: "3566" },
  { value: "3571 - GRAND WAILEA RESORT", key: "3571" },
  { value: "3576 - LA QUINTA RESORT", key: "3576" },
  { value: "3578 - FRANKENMUTH BAVARIAN", key: "3578" },
  { value: "3580 - HOTEL DEL CORONADO", key: "3580" },
  { value: "3589 - DORAL GOLF RESORT", key: "3589" },
  { value: "3594 - ARIZONA BILTMORE", key: "3594" },
  { value: "3596 - WYNN LAS VEGAS", key: "3596" },
  { value: "3597 - RIVERSIDE RESORT HOTEL", key: "3597" },
  { value: "3226 - SKYWAYS", key: "3226" },
  { value: "3236 - AIR ARABIA AIRLINE", key: "3236" },
  { value: "3245 - EASYJET", key: "3245" },
  { value: "3246 - RYANAIR", key: "3246" },
  { value: "3247 - GOL AIRLINES", key: "3247" },
  { value: "3248 - TAM AIRLINES", key: "3248" },
  { value: "3260 - SPIRIT AIRLINES", key: "3260" },
  { value: "3355 - SIXT CAR RENTAL", key: "3355" },
  { value: "6050 - Quasi Cash, Fin Inst", key: "6050" },
  { value: "3560 - ALLADDIN RESORT AND CASINO", key: "3560" },
  { value: "3079 - JETSTAR Airlines", key: "3079" },
  { value: "3131 - VLM Airlines", key: "3131" },
  { value: "3559 - CANDLEWOOD SUITES", key: "3559" },
  { value: "3602 - HUDSON HOTEL", key: "3602" },
  { value: "3604 - HILTON GARDEN INN", key: "3604" },
  { value: "3607 - FONTAINEBLEAU RESORTS", key: "3607" },
  { value: "3003 - EUROFLY AIRLINES", key: "3003" },
  { value: "3608 - GAYLORD OPRYLAND", key: "3608" },
  { value: "3609 - GAYLORD PALMS", key: "3609" },
  { value: "3613 - MICROTEL INNS & SUITES", key: "3613" },
  { value: "3019 - Germanwings - GRMNWGAIR", key: "3019" },
  { value: "3059 - DBA AIRLINES", key: "3059" },
  { value: "3062 - HAPAG-LLOYD EXPRESS AIRLINES", key: "3062" },
  { value: "3296 - AIR BERLIN", key: "3296" },
  { value: "3619 - ALOFT", key: "3619" },
  { value: "3614 - AMERICINN", key: "3614" },
  { value: "3617 - AMERICA'S BEST VALUE INN", key: "3617" },
  { value: "3618 - GREAT WOLF", key: "3618" },
  { value: "5333 - Hypermarket", key: "5333" },
  { value: "3174 - Jetblue Airways", key: "3174" },
  { value: "3621 - Extended Stay", key: "3621" },
  { value: "3626 - Studio Plus", key: "3626" },
  { value: "3627 - Extended Stay America", key: "3627" },
  { value: "3630 - Extended Stay Deluxe", key: "3630" },
  { value: "3800 - Homestead Suites", key: "3800" },
  { value: "3807 - Element", key: "3807" },
  { value: "3811 - PREMIER INN", key: "3811" },
  { value: "3801 - WILDERNESS HOTEL & RESORT", key: "3801" },
  { value: "3068 - Air Astana", key: "3068" },
  { value: "3812 - Hyatt Place", key: "3812" },
  { value: "3813 - Hotel Indigo", key: "3813" },
  { value: "3814 - The Roosevelt Hotel Ny", key: "3814" },
  { value: "3808 - LXR", key: "3808" },
  { value: "3815 - NICKELODEON SUITES", key: "3815" },
  { value: "3072 - CEBU Pacific Airlines", key: "3072" },
  { value: "3818 - MainStay Suites", key: "3818" },
  { value: "6539 - Funding [Excluding MoneySend]", key: "6539" },
  { value: "3819 - OXFORD SUITES", key: "3819" },
  { value: "3820 - JUMEIRAH ESSEX HOUSE", key: "3820" },
  { value: "3821 - CARIBE ROYALE", key: "3821" },
  { value: "3802 - THE PALACE HOTEL", key: "3802" },
  { value: "3822 - CROSSLAND", key: "3822" },
  { value: "6536 - MoneySend Intracountry", key: "6536" },
  { value: "6537 - MoneySend Intercountry", key: "6537" },
  { value: "6538 - MoneySend Funding", key: "6538" },
  { value: "3823 - GRAND SIERRA RESORT", key: "3823" },
  { value: "3824 - ARIA", key: "3824" },
  { value: "3825 - VDARA", key: "3825" },
  { value: "3826 - AUTOGRAPH", key: "3826" },
  { value: "3827 - GALT HOUSE", key: "3827" },
  { value: "3828 - COSMOPOLITAN OF LAS VEGAS", key: "3828" },
  { value: "6540 - POI Funding Transactions [Excluding MoneySend]", key: "6540" },
  { value: "3829 - Country Inn By Carlson", key: "3829" },
  { value: "3830 - Park Plaza Hotel", key: "3830" },
  { value: "3831 - Waldorf", key: "3831" },
  { value: "3816 - HOME2 SUITES", key: "3816" },
  { value: "9754 - Gambling - Horse Racing, Dog Racing, Non-Sports Intrastate Internet Gambling", key: "9754" },
  { value: "3300 - AZUL AIR", key: "3300" },
  { value: "3301 - VANILLA AIR/WIZZ AIR", key: "3301" },
  { value: "3069 - Sun Country Airlines - SUNCNTRY", key: "3069" },
  { value: "5815 - Digital Goods - Audiovisual Media Including Books, Movies, and Music", key: "5815" },
  { value: "5816 - Digital Goods - Games", key: "5816" },
  { value: "5817 - Digital Goods - Software Applications (Excluding Games)", key: "5817" },
  { value: "5818 - Digital Goods - Multi-Category", key: "5818" },
  { value: "7800 - Government Owned Lottery", key: "7800" },
  { value: "7801 - Internet Gambling", key: "7801" },
  { value: "7802 - Government Licensed Horse/Dog Racing", key: "7802" },
  { value: "3832 - CURIO HOTELS", key: "3832" },
  { value: "3833 - CANOPY", key: "3833" },
  { value: "6513 - Real Estate Agents and Managers", key: "6513" },
  { value: "3167 - AEROCONTINENTE", key: "3167" },
  { value: "3177 - AIRTRAN AIRWAYS", key: "3177" },
  { value: "3183 - OMAN AVIATION SERVICES", key: "3183" },
  { value: "3188 - VIRGIN EXPRESS", key: "3188" },
  { value: "3211 - NORWEGIAN AIR SHUTTLE", key: "3211" },
  { value: "3521 - ROYAL LAHAINA RESORTS", key: "3521" },
  { value: "3600 - SaDdlebrook Resort Tampa", key: "3600" },
  { value: "3601 - Tradewinds resorts", key: "3601" },
  { value: "3784 - FIRST HOSPITALITY HOTELS", key: "3784" },
  { value: "3786 - OHANA HOTELS OF HAWAII", key: "3786" },
  { value: "3787 - CARIBE ROYALE RESORTS", key: "3787" },
  { value: "3788 - ALA MOANA HOTEL", key: "3788" },
  { value: "3789 - SMUGGLER'S NOTCH RESORT", key: "3789" },
  { value: "3790 - RAFFLES HOTELS", key: "3790" },
  { value: "3791 - STAYBRIDGE SUITES", key: "3791" },
  { value: "3797 - ATLANTIC CITY HILTON RESORTS", key: "3797" },
  { value: "3799 - HALE KOA HOTEL", key: "3799" },
  { value: "4723 - Package Tour Operators - Germany Only", key: "4723" },
  { value: "9702 - Emergency Services (GCAS)", key: "9702" },
  { value: "9950 - NI Utility Payments", key: "9950" },
  { value: "3834 - Baymont Inn & Suites", key: "3834" },
  { value: "3835 - Dolce Hotels and Resorts", key: "3835" },
  { value: "3836 - Hawthorn Suites by Wyndham", key: "3836" },
  { value: "5262 - Online Marketplaces", key: "5262" },
  { value: "6535 - Value Purchase - Member Financial Institution", key: "6535" },
  { value: "5949 - Sewing,needlework,fabric", key: "5949" },
  { value: "5950 - Glassware And Crystal Store", key: "5950" },
  { value: "5960 - Direct Marketing-Insurance", key: "5960" },
  { value: "5962 - Direct Marketing-Travel Rel", key: "5962" },
  { value: "5963 - Door-to-Door Sales", key: "5963" },
  { value: "5964 - Direct Marketing-Catalog Me", key: "5964" },
  { value: "5965 - Direct Marketing-Combinatio", key: "5965" },
  { value: "5966 - Direct Marketing-Outbound T", key: "5966" },
  { value: "5967 - Direct Marketing-Inbound Te", key: "5967" },
  { value: "5968 - Direct Marketing-Continuity", key: "5968" },
  { value: "5969 - Direct Marketing-Other Dire", key: "5969" },
  { value: "5970 - Artists Supply Stores", key: "5970" },
  { value: "5971 - Art Dealers & Galleries", key: "5971" },
  { value: "5972 - Stamp & Coin Stores", key: "5972" },
  { value: "5973 - Religious Goods Stores", key: "5973" },
  { value: "5975 - Hearing Aid Sales,service", key: "5975" },
  { value: "5976 - Orthopedic Goods - Artifici", key: "5976" },
  { value: "5977 - Cosmetic Stores", key: "5977" },
  { value: "5978 - Typewriter Stores - Sales", key: "5978" },
  { value: "5983 - Fuel Dealers - Fuel Oil", key: "5983" },
  { value: "5993 - Cigar Stores And Stands", key: "5993" },
  { value: "5994 - News Dealers And Newsstands", key: "5994" },
  { value: "5995 - Pet Shops-pet Foods & Suppl", key: "5995" },
  { value: "5996 - Swimming Pools", key: "5996" },
  { value: "5997 - Electric Razor Stores", key: "5997" },
  { value: "5998 - Tent & Awning Shops", key: "5998" },
  { value: "6012 - Member Financial Institutio", key: "6012" },
  { value: "6022 - Finan. Inst.(RCL Internal)", key: "6022" },
  { value: "6023 - State Banks (RCL Internal)", key: "6023" },
  { value: "6025 - Nat. Banks  (RCL Internal)", key: "6025" },
  { value: "6026 - Nat. Banks Non Federal (INT", key: "6026" },
  { value: "6028 - Unicorporated Private Banks", key: "6028" },
  { value: "6211 - Security Brokers/dealers In", key: "6211" },
  { value: "6300 - Insurrance Sales And Underw", key: "6300" },
  { value: "6381 - Insurance", key: "6381" },
  { value: "6399 - Insurance - Unclassified", key: "6399" },
  { value: "7011 - Hotels,Motels,Resorts-Lodgi", key: "7011" },
  { value: "7012 - Timeshares", key: "7012" },
  { value: "7032 - Sporting And Recreat. Camps", key: "7032" },
  { value: "7033 - Trailer Parks & Campgrounds", key: "7033" },
  { value: "7210 - Laundry,cleaning & Garment", key: "7210" },
  { value: "7211 - Laundry Services-family & C", key: "7211" },
  { value: "7216 - Dry Cleaners", key: "7216" },
  { value: "7217 - Carpet And Upholstery Clean", key: "7217" },
  { value: "7221 - Photographic Studios, Portr", key: "7221" },
  { value: "7230 - Beauty Shops & Barber Shops", key: "7230" },
  { value: "7251 - Shoe Repair Shops", key: "7251" },
  { value: "7261 - Funeral Service & Crematori", key: "7261" },
  { value: "7273 - Dating & Escort Services", key: "7273" },
  { value: "7276 - Tax Preparation Service", key: "7276" },
  { value: "7277 - Counseling Service - Debt", key: "7277" },
  { value: "7278 - Buying/shopping Services", key: "7278" },
  { value: "7296 - Clothing Rental - Costumes", key: "7296" },
  { value: "7297 - Massage Parlors", key: "7297" },
  { value: "7298 - Health & Beauty Spas", key: "7298" },
  { value: "7299 - Other Services (Not Elsewhe", key: "7299" },
  { value: "7311 - Advertising Services", key: "7311" },
  { value: "7321 - Consumer Credit Reporting A", key: "7321" },
  { value: "7333 - Commercial Photography,art", key: "7333" },
  { value: "7338 - Quick-copy & Reproduction", key: "7338" },
  { value: "7339 - Stenographic & Secretarial", key: "7339" },
  { value: "7342 - Disinfecting & Exterminatin", key: "7342" },
  { value: "7349 - Cleaning & Maintenance", key: "7349" },
  { value: "7361 - Employment Agencies", key: "7361" },
  { value: "7372 - Computer And Data Processin", key: "7372" },
  { value: "7375 - Info Retrieval Services", key: "7375" },
  { value: "7379 - Computer Main/Repair Servic", key: "7379" },
  { value: "7392 - Management, Consulting", key: "7392" },
  { value: "7393 - Detective Agencies", key: "7393" },
  { value: "7394 - Equipment Rental & Leasing", key: "7394" },
  { value: "7395 - Photofinishing Laboratories", key: "7395" },
  { value: "7399 - Business Services", key: "7399" },
  { value: "7512 - Automobile Rental & Leasing", key: "7512" },
  { value: "7513 - Truck & Utility Trailer Ren", key: "7513" },
  { value: "7519 - Motor Home & Recreational V", key: "7519" },
  { value: "7523 - Automobile Parking Lots", key: "7523" },
  { value: "7531 - Automotive Top & Body Shops", key: "7531" },
  { value: "7534 - Tire Retreading & Repair Sh", key: "7534" },
  { value: "7535 - Automotive Paint Shops", key: "7535" },
  { value: "7538 - Automotive Service Shops", key: "7538" },
  { value: "7542 - Car Washes", key: "7542" },
  { value: "7549 - Towing Services", key: "7549" },
  { value: "7622 - Electronic Repair Shops", key: "7622" },
  { value: "7623 - Air Conditioning & Refriger", key: "7623" },
  { value: "7629 - Electrical & Small Appl.rep", key: "7629" },
  { value: "7631 - Watch, Clock & Jewelry Repa", key: "7631" },
  { value: "7641 - Reupholstery & Furnit. Repa", key: "7641" },
  { value: "7692 - Welding Repair", key: "7692" },
  { value: "7699 - Miscellaneous Repair Shops", key: "7699" },
  { value: "7829 - Motion Picture/Video Prd/Di", key: "7829" },
  { value: "7832 - Motion Picture Theaters", key: "7832" },
  { value: "7841 - DVD/Video Tape Rental Store", key: "7841" },
  { value: "7911 - Dance Halls, Studios", key: "7911" },
  { value: "7922 - Theatrical Producers", key: "7922" },
  { value: "7929 - Bands, Orchestras And Misce", key: "7929" },
  { value: "7932 - Billiard And Pool Establish", key: "7932" },
  { value: "7933 - Bowling Alleys", key: "7933" },
  { value: "7941 - Commercial Sports", key: "7941" },
  { value: "7991 - Tourist Attr. And Exhibitio", key: "7991" },
  { value: "7992 - Golf Courses - Public Video", key: "7992" },
  { value: "7993 - Video Amusement Game Suppli", key: "7993" },
  { value: "7013 - Real Estate Agent - Brokers", key: "7013" },
  { value: "9406 - Government-Owned Lotteries (Non-U.S region)", key: "9406" },
];
