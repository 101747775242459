import { FC, ReactNode, useEffect } from "react";
import { Form, Select, SelectProps, TooltipProps } from "antd";

import { useConfigurationApi, useReduxState } from "@ni/common/hooks";
import { NetWorkFormItemProps } from "@ni/common/types";

import { FormItemLabel } from "../Labels";
import { TooltipInfo } from "../TooltipInfo";

export interface DictionarySelectProps extends SelectProps {
  code: string;
  formItemOptions?: NetWorkFormItemProps;
}

export const DictionarySelect: FC<DictionarySelectProps> = ({ code, formItemOptions, ...props }) => {
  const [data, setData] = useReduxState<SelectProps["options"]>(`Cached-${code}`, []);
  const { getDictionariesByCode } = useConfigurationApi();

  useEffect(() => {
    if (!data?.length)
      void getDictionariesByCode(code)
        .then(response => {
          setData(response.dictionaries?.map(option => ({ value: option.value, label: option.displayValue })));
        })
        .catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const { options, ...restProps } = props;

  if (formItemOptions?.name) {
    return (
      <Form.Item
        {...formItemOptions}
        tooltip={undefined}
        label={
          <TooltipInfo
            label={formItemOptions?.label}
            code={formItemOptions?.name as string}
            tooltipProps={
              (formItemOptions?.tooltip
                ? typeof formItemOptions.tooltip === "string"
                  ? { title: formItemOptions?.tooltip }
                  : typeof formItemOptions.tooltip === "object" &&
                      (formItemOptions.tooltip as { title: ReactNode })?.title
                    ? { title: (formItemOptions.tooltip as { title: ReactNode }).title as ReactNode }
                    : formItemOptions.tooltip
                : {}) as TooltipProps
            }
          />
        }
      >
        <Select {...restProps} loading={!data?.length}>
          {[...(options ?? []), ...(data ?? [])].map(option => (
            <Select.Option key={option.value} value={option.value}>
              <FormItemLabel label={option.label} code={option.value as string} />
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Select {...restProps} loading={!data?.length}>
      {[...(options ?? []), ...(data ?? [])].map(option => (
        <Select.Option key={option.value} value={option.value}>
          <FormItemLabel label={option.label} code={option.value as string} />
        </Select.Option>
      ))}
    </Select>
  );
};
